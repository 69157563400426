import styled from 'styled-components';
import { MediaQuery } from '../constants';

export const PageHeader = styled.header`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const PageSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;

  padding: 48px 32px;
`;

export const PageForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DataField = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const ListField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetricsGrid = styled.main`
  padding-top: 16px;
  display: grid;

  @media (min-width: ${MediaQuery.Small}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  @media (min-width: ${MediaQuery.Medium}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (min-width: ${MediaQuery.Large}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ListGrid = styled.div`
  padding-top: 16px;
  display: grid;

  @media (min-width: ${MediaQuery.Small}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  @media (min-width: ${MediaQuery.Medium}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (min-width: ${MediaQuery.Large}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

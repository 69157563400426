import React from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 6px 0px;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;

const ErrorSpacer = styled.div`
  /* height: ${({ theme }) => theme.defaultFontSize}; */
  height: 21px;
`;

interface IProps {
  errorMessage?: string;
}

export const ErrorDisplay = ({ errorMessage }: IProps) => {
  return (
    <ErrorWrapper>
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : <ErrorSpacer />}
    </ErrorWrapper>
  );
};

export enum DashboardActionType {
  FETCH_DAILY_USERS = 'FETCH_DAILY_USERS',
  FETCH_DAILY_USERS_SUCCESS = 'FETCH_DAILY_USERS_SUCCESS',
  FETCH_DAILY_USERS_FAILURE = 'FETCH_DAILY_USERS_FAILURE',
  FETCH_ALL_USERS = 'FETCH_ALL_USERS',
  FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS',
  FETCH_ALL_USERS_FAILURE = 'FETCH_ALL_USERS_FAILURE',
  FETCH_WEEKLY_USERS = 'FETCH_WEEKLY_USERS',
  FETCH_WEEKLY_USERS_SUCCESS = 'FETCH_WEEKLY_USERS_SUCCESS',
  FETCH_WEEKLY_USERS_FAILURE = 'FETCH_WEEKLY_USERS_FAILURE',
  FETCH_MONTHLY_USERS = 'FETCH_MONTHLY_USERS',
  FETCH_MONTHLY_USERS_SUCCESS = 'FETCH_MONTHLY_USERS_SUCCESS',
  FETCH_MONTHLY_USERS_FAILURE = 'FETCH_MONTHLY_USERS_FAILURE',
  FETCH_TRACK_DATA = 'FETCH_TRACK_DATA',
  FETCH_TRACK_DATA_SUCCESS = 'FETCH_TRACK_DATA_SUCCESS',
  FETCH_TRACK_DATA_FAILURE = 'FETCH_TRACK_DATA_FAILURE',
  FETCH_TRACK_WEEKLY_TRENDING = 'FETCH_TRACK_WEEKLY_TRENDING',
  FETCH_TRACK_WEEKLY_TRENDING_SUCCESS = 'FETCH_TRACK_WEEKLY_TRENDING_SUCCESS',
  FETCH_TRACK_WEEKLY_TRENDING_FAILURE = 'FETCH_TRACK_WEEKLY_TRENDING_FAILURE',
  FETCH_TRACK_MONTHLY_TRENDING = 'FETCH_TRACK_MONTHLY_TRENDING',
  FETCH_TRACK_MONTHLY_TRENDING_SUCCESS = 'FETCH_TRACK_MONTHLY_TRENDING_SUCCESS',
  FETCH_TRACK_MONTHLY_TRENDING_FAILURE = 'FETCH_TRACK_MONTHLY_TRENDING_FAILURE',
  FETCH_TRACK_ALL_TIME_TRENDING = 'FETCH_TRACK_ALL_TIME_TRENDING',
  FETCH_TRACK_ALL_TIME_TRENDING_SUCCESS = 'FETCH_TRACK_ALL_TIME_TRENDING_SUCCESS',
  FETCH_TRACK_ALL_TIME_TRENDING_FAILURE = 'FETCH_TRACK_ALL_TIME_TRENDING_FAILURE',
}

import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';

import { SidebarLayout } from '../common';
import { DashboardMetrics } from './containers';

export const Dashboard = (props: RouteComponentProps) => {
  return (
    <SidebarLayout>
      <Router>
        <DashboardMetrics path="/" />
      </Router>
    </SidebarLayout>
  );
};

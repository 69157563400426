import React from 'react';
import styled from 'styled-components';

import { interfaces, CalloutText } from '../../common';
import { AudioAssetListItem } from './AudioAssetListItem';

const AudioAssetListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
interface IProps {
  audioAssets?: interfaces.IAudioAsset[];
}

export const AudioAssetList = ({ audioAssets }: IProps) => {
  return audioAssets && audioAssets.length > 0 ? (
    <AudioAssetListWrapper>
      {audioAssets.map((tempAudioAsset: interfaces.IAudioAsset) => {
        return <AudioAssetListItem key={tempAudioAsset.id} audioAsset={tempAudioAsset} />;
      })}
    </AudioAssetListWrapper>
  ) : (
    <CalloutText>No audio assets found...</CalloutText>
  );
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { IGlobalStoreState } from './interfaces';
import { closeModal } from './actions';

const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.65);
`;

export const ModalContainer = () => {
  const { isOpen, currentModal } = useSelector((state: IGlobalStoreState) => state.modal);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return isOpen ? <ModalBackground onClick={onClose}>{currentModal}</ModalBackground> : null;
};

import { interfaces } from '../common';

export const MainRoutes: { [key: string]: interfaces.IRoute } = {
  Dashboard: {
    name: 'Dashboard',
    path: '/',
  },
  Courses: {
    name: 'Courses',
    path: '/courses',
  },
  Modules: {
    name: 'Playlist/Modules',
    path: '/modules',
  },
  AudioAssets: {
    name: 'Audio Assets',
    path: '/audio-assets',
  },
  Announcements: {
    name: 'Announcements',
    path: '/announcements',
  },
};

export enum SubRoutes {
  Add = '/add',
  RelativeAdd = 'add',
  Course = 'course',
  Module = 'module',
  AudioAsset = 'audio',
  Announcement = 'announcement',
}

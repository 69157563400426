import { interfaces } from '../common';
import { LocalStorageKey } from '../constants';

export const isAdmin = (user: interfaces.IUser) => {
  if (user && user.permissions.includes(interfaces.IPermission.ADMIN)) {
    return true;
  }

  return false;
};

export const saveUserInLocalStorage = (user: interfaces.IUser) => {
  try {
    const tempUser = JSON.stringify(user);
    localStorage.setItem(LocalStorageKey.User, tempUser);
  } catch (error: any) {
    console.warn(`Could not save user to local storage: ${error.message}`);
  }
};

export const getUserFromLocalStorage = (): interfaces.IUser | null => {
  try {
    const tempUser = localStorage.getItem(LocalStorageKey.User);
    if (tempUser) {
      const tempParsedUser = JSON.parse(tempUser);
      return tempParsedUser;
    } else return null;
  } catch (error: any) {
    console.warn(`Could not get user from local storage: ${error.message}`);
  }

  return null;
};

export const clearUserFromLocalStorage = () => {
  localStorage.removeItem(LocalStorageKey.User);
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, interfaces } from '../../common';
import { fetchModules } from '../../modules/actions';

export const ModuleContent = ({
  label,
  isLibraryContentOnly,
}: {
  label: string;
  isLibraryContentOnly: boolean;
}) => {
  const { modules }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { modules: allModules, error, isLoading } = modules;
  const libraryModules = allModules.filter(
    (tempModule) => tempModule.isLibrary === isLibraryContentOnly
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModules());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>{label}</h4>
        <h3>{isLoading ? `—` : libraryModules.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

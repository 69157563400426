import { ICourseStore } from '../courses';
import { IUserStore } from '../user';
import { IModuleStore } from '../modules';
import { IAudioAssetStore } from '../audioAssets';
import { IDashboardStore } from '../dashboard';
import { IAnnouncementStore } from '../announcements';
import { IModalState } from './reducers';

import { ThemeType } from './styles/theme';

export type ID = number | string;

export enum AnnouncementType {
  COURSE = 'COURSE',
  AUDIO_ASSET = 'AUDIO_ASSET',
  MODULE = 'MODULE',
}

export interface IAnnouncement {
  id?: ID;
  desc: string;
  label: string;
  isPublished: boolean;
  contentDuration?: string;
  order: number;
  title: string;
  type: AnnouncementType;
  imageUrl?: string;
  image?: File;
  linkedContentItemId: ID;
  createdAt?: number;
  updatedAt?: number;

  // front end prop for tracking when we last fetched it
  lastUpdated?: number;
}

export interface IStyledComponent {
  theme: ThemeType;
}

export enum IPermission {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface ILoginParams {
  email: string;
  password: string;
}

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  permissions: IPermission[];
  token: string;
}

export interface IRoute {
  name: string;
  path: string;
}

export interface ICourse {
  id?: ID;
  isPublished?: boolean;
  desc: string | null;
  level: number;
  order: number;
  title: string;
  averageTrackLength?: string | null;
  modules?: IModule[];
  lastUpdated?: number;
  imageUrl?: string;
  image?: File;
}

// A Playlist
export interface IModule {
  id?: ID;
  title: string;
  desc: string | null;
  isLibrary?: boolean;
  order: number;
  level: number;
  trackCount?: number;
  courseId?: ID | null;
  lastUpdated?: number;
  audioAssets?: IAudioAsset[];
  isPublished?: boolean;
}

export interface IAudioAsset {
  id?: ID;
  title: string;
  order: number | null;
  level: number;
  desc: string | null;
  url: string | null;
  duration?: number;
  type?: any;
  filepath?: string | null;
  imageUrl?: string | null;
  moduleTitle?: string | null;
  moduleId?: string | null;
  isLibrary?: boolean;
  timesStarted?: number;
  filename?: string | null;
  storageKey?: string | null;
  file?: File;
  lastUpdated?: number;
  isPublished?: boolean;
}

export interface IGlobalStoreState {
  user: IUserStore;
  courses: ICourseStore;
  modules: IModuleStore;
  audioAssets: IAudioAssetStore;
  dashboard: IDashboardStore;
  modal: IModalState;
  announcements: IAnnouncementStore;
}

// For all tracks
export interface ITrackData {
  allTimePlays: number;
  dailyPlays: number;
  weeklyPlays: number;
  monthlyPlays: number;
}

// For single tracks
export interface ITrackAnalyticsData {
  timesCompleted: number;
  timesAbandoned: number;
  completionRatePercentage: number;
  dailyPlays: number;
  weeklyPlays: number;
  monthlyPlays: number;
}

export interface ITrackTrendingData {
  playCount: number;
  id: ID;
  title: string;
}

import React from 'react';

import { ModalActionType } from './constants';

export interface IModalState {
  isOpen: boolean;
  currentModal: React.ReactNode | null;
}

const initialModalState: IModalState = {
  isOpen: false,
  currentModal: null,
};

export const modalReducer = (
  state = initialModalState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ModalActionType.OPEN:
      return {
        ...state,
        isOpen: true,
        currentModal: payload.currentModal,
      };
    case ModalActionType.CLOSE:
      return {
        ...state,
        isOpen: false,
        currentModal: null,
      };
    case ModalActionType.SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: payload.currentModal,
      };
    default:
      return state;
  }
};

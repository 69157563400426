export enum CourseActionType {
  FETCH_COURSES = 'FETCH_COURSES',
  FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS',
  FETCH_SINGLE_COURSE = 'FETCH_SINGLE_COURSE',
  FETCH_SINGLE_COURSE_SUCCESS = 'FETCH_SINGLE_COURSE_SUCCESS',
  UPDATE_SINGLE_COURSE = 'UPDATE_SINGLE_COURSE',
  ADD_SINGLE_COURSE = 'ADD_SINGLE_COURSE',
  DELETE_SINGLE_COURSE = 'DELETE_SINGLE_COURSE',
  REORDER_COURSES = 'REORDER_COURSES',
  REORDER_COURSES_SUCCESS = 'REORDER_COURSES_SUCCESS',
  GENERIC_COURSE_FAILURE = 'GENERIC_COURSE_FAILURE',
}

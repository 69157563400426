import axios, { AxiosInstance } from 'axios';

const LOCAL_URL = `http://localhost:3001`;

export const getAPIURL = () => {
  return process.env.REACT_APP_API_URL || LOCAL_URL;
};

const api: AxiosInstance = axios.create({
  baseURL: getAPIURL(),
});

const setToken = (token: string) => {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
};

export { api, setToken };

import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { store } from '../redux';
import { theme, GlobalStyles, ModalContainer } from '../common';
import { Router as AppRouter } from '../router/Router';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyles />
          <ModalContainer />
          <AppRouter />
        </Fragment>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

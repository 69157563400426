import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { SubRoutes } from '../constants';
import { SidebarLayout } from '../common';
import { AllAudioAssets, AddAudioAsset, EditAudioAsset } from './containers';

export const AudioAssets = (props: RouteComponentProps) => {
  return (
    <SidebarLayout>
      <Router>
        <AllAudioAssets path="/" />
        <AddAudioAsset path={SubRoutes.RelativeAdd} />
        <AddAudioAsset path={`${SubRoutes.RelativeAdd}/:moduleId`} />
        <EditAudioAsset path={`${SubRoutes.AudioAsset}/:audioAssetId`} />
      </Router>
    </SidebarLayout>
  );
};

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import { MainRoutes } from '../../constants';
import { PageSection, PageHeader, interfaces, ErrorDisplay, Button } from '../../common';
import { IModuleStore } from '../reducers';
import { createModule } from '../actions';
import { ModuleForm } from '../components';

interface IProps extends RouteComponentProps {
  courseId?: string;
}

export const AddModule = ({ courseId = '' }: IProps) => {
  const { isLoading, error } = useSelector((state: { modules: IModuleStore }) => state.modules);
  const dispatch = useDispatch();
  const [module, setModule] = useState<interfaces.IModule>({
    title: '',
    desc: '',
    order: 0,
    level: 0,
    courseId,
    isPublished: true,
  });

  const onUpdateModule = (updatedField: { [key: string]: any }) => {
    setModule({ ...module, ...updatedField });
  };

  const onCreateModule = async () => {
    try {
      await dispatch(createModule(module));
      navigate(MainRoutes.Modules.path);
    } catch (error: any) {
      console.log('error creating course — ', error);
    }
  };

  const isValid = () => {
    if (!module.title) {
      return false;
    }

    return true;
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>Add Module</h1>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <ModuleForm
        module={module}
        onUpdateForm={onUpdateModule}
        onSubmit={onCreateModule}
        isEditMode={true}
      />
      <Button
        onClick={onCreateModule}
        style={{ alignSelf: 'flex-end' }}
        disabled={!isValid()}
        isLoading={isLoading}
      >
        Create
      </Button>
    </PageSection>
  );
};

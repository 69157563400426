import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, interfaces } from '../../common';
import { fetchTrackData } from '../actions';
import { Status } from '../../constants';

export const TrackPlayContent = ({
  label,
  dataKey,
}: {
  label: string;
  dataKey: keyof interfaces.ITrackData;
}) => {
  const { dashboard }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );

  const { status, error, ...trackData } = dashboard.trackData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTrackData());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>{label}</h4>
        <h3>{status === Status.Loading ? `—` : trackData[dataKey]}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

import React from 'react';
import styled from 'styled-components';

import { interfaces, CalloutText } from '../../common';
import { ModuleItem } from './ModuleItem';

const ModuleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

interface IProps {
  modules?: interfaces.IModule[];
}

export const ModuleList = ({ modules }: IProps) => {
  return modules && modules.length > 0 ? (
    <ModuleListWrapper>
      {modules.map((tempModule: interfaces.IModule) => {
        return <ModuleItem key={tempModule.id} module={tempModule} />;
      })}
    </ModuleListWrapper>
  ) : (
    <ModuleListWrapper>
      <CalloutText>No modules found...</CalloutText>
    </ModuleListWrapper>
  );
};

import React from 'react';
import { Router } from '@reach/router';

import { MainRoutes } from '../constants';
import { Dashboard } from '../dashboard';
import { Modules } from '../modules';
import { AudioAssets } from '../audioAssets';
import { Courses } from '../courses';
import { Announcements } from '../announcements';

export const AuthRouter = () => {
  return (
    <Router>
      <Dashboard path={'/*'} />
      <Courses path={`${MainRoutes.Courses.path}/*`} />
      <Modules path={`${MainRoutes.Modules.path}/*`} />
      <AudioAssets path={`${MainRoutes.AudioAssets.path}/*`} />
      <Announcements path={`${MainRoutes.Announcements.path}/*`} />
    </Router>
  );
};

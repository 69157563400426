import React from 'react';
import styled from 'styled-components';

export const SidebarContent: React.FC = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

const StyledContent = styled.div`
  overflow: auto;
`;

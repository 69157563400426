import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import {
  interfaces,
  Button,
  LinkButton,
  PageSection,
  PageHeader,
  ErrorDisplay,
  openModal,
  Modal,
  ModalType,
} from '../../common';
import { ModuleList } from '../../modules';
import { MainRoutes, SubRoutes } from '../../constants';
import { ICourseStore } from '../reducers';
import { fetchSingleCourse, editCourse, deleteCourse as deleteCourseAction } from '../actions';
import { CourseForm } from '../components';

interface IProps extends RouteComponentProps {
  courseId?: interfaces.ID;
}

export const EditCourse = ({ courseId }: IProps) => {
  const { isLoading, error, courses } = useSelector(
    (state: { courses: ICourseStore }) => state.courses
  );
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentCourse, setCurrentCourse] = useState<interfaces.ICourse | undefined>(undefined);
  const [currentEditableCourse, setCurrentEditableCourse] = useState<interfaces.ICourse | null>(
    null
  );

  useEffect(() => {
    const course = courses.find((tempCourse: interfaces.ICourse) => tempCourse.id === courseId);
    if (courseId) {
      dispatch(fetchSingleCourse(courseId));
    }

    if (course) {
      setCurrentCourse(course);
      setCurrentEditableCourse(course);
    }
  }, [courseId, dispatch, courses]);

  const onUpdateCourse = (updatedField: { [key: string]: any }) => {
    setCurrentEditableCourse(
      currentEditableCourse
        ? { ...currentEditableCourse, ...updatedField }
        : { title: '', desc: null, level: 0, order: 0, isPublished: true, ...updatedField }
    );
  };

  const onSaveEdittedCourse = async () => {
    if (!currentEditableCourse) {
      return;
    }

    try {
      await dispatch(editCourse(currentEditableCourse));
      navigate(MainRoutes.Courses.path);
    } catch (error: any) {
      console.log('error — ', error);
    }
  };

  const onDeleteCourse = () => {
    dispatch(
      openModal(
        <Modal
          title="Delete Course?"
          message="Are you sure? This will delete all associated modules (and their audio assets)!"
          onConfirm={deleteCourse}
          type={ModalType.Danger}
        />
      )
    );
  };

  const deleteCourse = async () => {
    navigate(MainRoutes.Courses.path);
    await dispatch(deleteCourseAction(currentCourse && currentCourse.id));
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>{currentCourse?.title}</h1>
        <Button onClick={() => setIsEditMode(!isEditMode)}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
      </PageHeader>
      <ErrorDisplay errorMessage={error}></ErrorDisplay>
      <CourseForm
        course={isEditMode ? currentEditableCourse : currentCourse || null}
        isEditMode={isEditMode}
        onUpdateForm={onUpdateCourse}
        onSubmit={onSaveEdittedCourse}
        onDelete={onDeleteCourse}
      ></CourseForm>
      <Button onClick={onSaveEdittedCourse} isLoading={isLoading} disabled={!isEditMode}>
        Save
      </Button>
      <h2 style={{ margin: `24px 0px 6px 0px` }}>Modules</h2>
      <ModuleList modules={currentCourse?.modules} />
      <LinkButton
        style={{ marginTop: 6 }}
        to={`${MainRoutes.Modules.path}${SubRoutes.Add}/${currentCourse?.id}`}
      >
        Add
      </LinkButton>
    </PageSection>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import {
  PageSection,
  PageHeader,
  Button,
  interfaces,
  ErrorDisplay,
  Modal,
  ModalType,
  openModal,
} from '../../common';
import { MainRoutes } from '../../constants';
import {
  fetchSingleAnnouncement,
  editAnnouncement,
  deleteAnnouncement as deleteAnnouncementAction,
} from '../actions';
import { AnnouncementForm } from '../components';

interface IProps extends RouteComponentProps {
  id?: interfaces.ID;
}

export const EditAnnouncement = ({ id }: IProps) => {
  const { isLoading, error, announcements } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.announcements
  );
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState<
    interfaces.IAnnouncement | undefined
  >(undefined);
  const [currentEditableAnnouncement, setCurrentEditableAnnouncement] =
    useState<interfaces.IAnnouncement | null>(null);

  useEffect(() => {
    const announcement = announcements.find(
      (tempAnnouncement: interfaces.IAnnouncement) => tempAnnouncement.id === id
    );

    if (id) {
      dispatch(fetchSingleAnnouncement(id));
    }

    if (announcement) {
      setCurrentAnnouncement(announcement);
      setCurrentEditableAnnouncement(announcement);
    }
  }, [id, dispatch, announcements]);

  const onUpdateAnnouncement = (updatedField: { [key: string]: any }) => {
    setCurrentEditableAnnouncement(
      currentEditableAnnouncement
        ? { ...currentEditableAnnouncement, ...updatedField }
        : {
            title: '',
            label: '',
            desc: '',
            order: 0,
            linkedContentItemId: '',
            type: interfaces.AnnouncementType.COURSE,
            isPublished: true,
            image: undefined,
            ...updatedField,
          }
    );
  };

  const onSaveEdittedAnnouncement = async () => {
    if (!currentEditableAnnouncement) {
      return;
    }

    try {
      await dispatch(editAnnouncement(currentEditableAnnouncement));
      navigate(MainRoutes.Announcements.path);
    } catch (error: any) {
      console.log('error — ', error);
    }
  };

  const onDeleteAnnouncement = () => {
    dispatch(
      openModal(
        <Modal
          title="Delete Announcement?"
          message="Are you sure? This action can not be undone."
          onConfirm={deleteAnnouncement}
          type={ModalType.Danger}
        />
      )
    );
  };

  const deleteAnnouncement = async () => {
    navigate(MainRoutes.Announcements.path);
    await dispatch(deleteAnnouncementAction(currentAnnouncement && currentAnnouncement.id));
  };

  const isValid = () => {
    if (
      !currentEditableAnnouncement?.title ||
      !currentEditableAnnouncement?.desc ||
      !currentEditableAnnouncement?.linkedContentItemId
    ) {
      return false;
    }

    return true;
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>{currentAnnouncement?.title || 'Loading...'}</h1>
        <Button onClick={() => setIsEditMode(!isEditMode)}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <AnnouncementForm
        onSubmit={onSaveEdittedAnnouncement}
        onUpdateForm={onUpdateAnnouncement}
        announcement={isEditMode ? currentEditableAnnouncement : currentAnnouncement || null}
        isEditMode={isEditMode}
        onDelete={onDeleteAnnouncement}
      />
      <Button
        onClick={onSaveEdittedAnnouncement}
        isLoading={isLoading}
        disabled={!isEditMode || !isValid()}
      >
        Save
      </Button>
    </PageSection>
  );
};

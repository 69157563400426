import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Draggable } from 'react-beautiful-dnd';

import { SubRoutes, MainRoutes, KeyPrefix } from '../../constants';
import { interfaces } from '../../common';

const ModuleLink = styled(Link)``;
const EmptyModuleDropZone = styled.div<{ isDraggingOver: boolean; isDraggingInProgress?: boolean }>`
  height: ${(props) => (props.isDraggingInProgress ? '24px' : '2px')};
  min-height: ${(props) => (props.isDraggingInProgress ? '24px' : '2px')};
  background-color: ${(props) => (props.isDraggingOver ? 'yellow' : 'transparent')};
  border-color: ${({ theme }) => theme.colors.primary};
  border-style: dashed;
  border-width: ${(props) => (props.isDraggingInProgress ? '2px' : '0px')};
`;
const ModuleDnDItemContainer = styled.div<{ isDraggingOver: boolean; isDragging: boolean }>`
  background-color: ${(props) => (props.isDraggingOver ? 'yellow' : 'white')};
  max-width: 400px;
  padding: 2px;
  border: ${({ isDragging, theme }) => (isDragging ? `2px solid ${theme.colors.primary}` : 'none')};
`;

interface IProps {
  module: interfaces.IModule;
  children?: React.ReactNode;
  provided: any;
  index: number;
  isDraggingOver: boolean;
  isDraggingInProgress?: boolean;
}

export const ModuleItemDnD = ({
  module,
  children,
  provided,
  index,
  isDraggingOver,
  isDraggingInProgress,
}: IProps) => {
  const isEmptyModule = (module.id! as string).includes(KeyPrefix.EmptyCourse);

  return (
    <div ref={provided.innerRef}>
      <Draggable draggableId={module.id as string} index={index} isDragDisabled={isEmptyModule}>
        {(provided, snapshot) => {
          return isEmptyModule ? (
            <EmptyModuleDropZone
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDraggingOver={isDraggingOver}
              isDraggingInProgress={isDraggingInProgress}
            />
          ) : (
            <ModuleDnDItemContainer
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDraggingOver={isDraggingOver}
              isDragging={snapshot.isDragging}
            >
              <ModuleLink to={`${MainRoutes.Modules.path}/${SubRoutes.Module}/${module.id}`}>
                {module.title}
              </ModuleLink>
            </ModuleDnDItemContainer>
          );
        }}
      </Draggable>
      {children}
    </div>
  );
};

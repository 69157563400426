import React from 'react';

import { ModalActionType } from './constants';

export const openModal = (currentModal: React.ReactNode) => {
  return {
    type: ModalActionType.OPEN,
    payload: { currentModal },
  };
};

export const closeModal = () => {
  return {
    type: ModalActionType.CLOSE,
    payload: null,
  };
};

export const setCurrentModal = (currentModal: React.ReactNode) => {
  return {
    type: ModalActionType.SET_CURRENT_MODAL,
    payload: { currentModal },
  };
};

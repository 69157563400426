import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { interfaces } from '../common';
import { MainRoutes } from '../constants';
import { clearUser } from '../user';

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  /* border-right: ${({ theme }) => `1px solid ${theme.colors.black}`}; */
`;

const NavigationWrapper = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizeH3};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 6px;
`;

const LogoutWrapper = styled.div`
  padding: 12px;
`;

const LogoutLink = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
`;

export const Sidebar = () => {
  const dispatch = useDispatch();

  return (
    <SidebarWrapper>
      <NavigationWrapper>
        {Object.values(MainRoutes).map((tempRoute: interfaces.IRoute) => (
          <StyledLink
            key={tempRoute.name}
            to={tempRoute.path}
            getProps={({ isCurrent }) => {
              return {
                style: {
                  fontWeight: isCurrent ? 'bold' : '400',
                },
              };
            }}
          >
            {tempRoute.name}
          </StyledLink>
        ))}
      </NavigationWrapper>
      <LogoutWrapper onClick={() => dispatch(clearUser())}>
        <LogoutLink>Logout</LogoutLink>
      </LogoutWrapper>
    </SidebarWrapper>
  );
};

import React, { ChangeEvent } from 'react';

import { interfaces, Select, Option, Row } from '../../common';
import { CourseSelect } from '../../courses/components';
import { ModuleSelect } from '../../modules/components';
import { AudioAssetSelect } from '../../audioAssets/components';

interface IProps {
  disabled?: boolean;
  announcement: interfaces.IAnnouncement;
  linkedContentItemId: interfaces.ID;
  onChange: (updatedFields: any) => void;
}

export const AnnouncementTypeSelect = ({
  linkedContentItemId,
  onChange,
  announcement,
  disabled,
}: IProps) => {
  const getTypesSelect = (type: interfaces.AnnouncementType) => {
    switch (type) {
      case interfaces.AnnouncementType.COURSE:
        return (
          <CourseSelect
            onChange={(event) => {
              onChange({ linkedContentItemId: event.target.value });
            }}
            value={linkedContentItemId as string}
            disabled={disabled || !announcement.type}
          />
        );
      case interfaces.AnnouncementType.MODULE:
        return (
          <ModuleSelect
            onChange={(event) => {
              onChange({ linkedContentItemId: event.target.value });
            }}
            value={linkedContentItemId as string}
            disabled={disabled || !announcement.type}
          />
        );
      case interfaces.AnnouncementType.AUDIO_ASSET:
        return (
          <AudioAssetSelect
            onChange={(event) => {
              onChange({ linkedContentItemId: event.target.value });
            }}
            value={linkedContentItemId as string}
            disabled={disabled || !announcement.type}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Row style={{ marginBottom: 6 }}>
      <Select
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          onChange({ type: event.target.value });
        }}
        style={{ marginRight: 6 }}
        value={announcement.type}
        disabled={disabled}
      >
        <Option key={interfaces.AnnouncementType.COURSE} value={interfaces.AnnouncementType.COURSE}>
          Course
        </Option>
        <Option key={interfaces.AnnouncementType.MODULE} value={interfaces.AnnouncementType.MODULE}>
          Module
        </Option>
        <Option
          key={interfaces.AnnouncementType.AUDIO_ASSET}
          value={interfaces.AnnouncementType.AUDIO_ASSET}
        >
          Audio Asset
        </Option>
      </Select>
      {getTypesSelect(announcement.type)}
    </Row>
  );
};

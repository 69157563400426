import { Dispatch } from 'redux';
import { CourseActionType } from './constants';

import {
  getCourses,
  getCourseById,
  createCourse as createCourseAPI,
  editCourse as editCourseAPI,
  deleteCourse as deleteCourseAPI,
  reorderCourses as reorderCoursesAPI,
} from '../api';
import { interfaces } from '../common';
import { Time, isOlderThan } from '../utils';

// All Courses
export const fetchCourses = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().courses.lastUpdated;
    if (!isOlderThan(Time.OneMinute, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchCoursesInit());
    try {
      const tempCourses = await getCourses();
      dispatch(fetchCoursesSuccess(tempCourses));
    } catch (error: any) {
      dispatch(genericCourseFailure(error.message));
    }
  };
};

const fetchCoursesInit = () => {
  return {
    type: CourseActionType.FETCH_COURSES,
    payload: null,
  };
};

const fetchCoursesSuccess = (courses: interfaces.ICourse[]) => {
  return {
    type: CourseActionType.FETCH_COURSES_SUCCESS,
    payload: { courses },
  };
};

// Single Course
export const fetchSingleCourse = (id: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingCourses = getState().courses.courses;
    const tempSingleExistingCourse = tempExistingCourses.find(
      (tempCourse: interfaces.ICourse) => tempCourse.id === id
    );

    try {
      if (tempSingleExistingCourse) {
        if (isOlderThan(Time.OneMinute, tempSingleExistingCourse.lastUpdated)) {
          // Refresh as it's out of date
          dispatch(fetchSingleCourseInit());
          const tempSingleCourse = await getCourseById(id);
          dispatch(updateSingleCourse(tempSingleCourse));
        }
      } else {
        // Doesnt exist at all, get a new version
        dispatch(fetchSingleCourseInit());
        const tempSingleCourse = await getCourseById(id);
        dispatch(addSingleCourse(tempSingleCourse));
      }
    } catch (error: any) {
      dispatch(genericCourseFailure(error.message));
    }
  };
};

const fetchSingleCourseInit = () => {
  return {
    type: CourseActionType.FETCH_SINGLE_COURSE,
    payload: null,
  };
};

const addSingleCourse = (course: interfaces.ICourse) => {
  return {
    type: CourseActionType.ADD_SINGLE_COURSE,
    payload: { course },
  };
};

// Create Course
export const createCourse = (course: interfaces.ICourse) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(fetchSingleCourseInit());
    try {
      const tempSingleCourse = await createCourseAPI(course);
      dispatch(addSingleCourse(tempSingleCourse));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericCourseFailure(error.message));
      return Promise.reject(error);
    }
  };
};

// Edit Course
export const editCourse = (course: interfaces.ICourse) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(fetchSingleCourseInit());
    try {
      const tempSingleCourse = await editCourseAPI(course);
      dispatch(updateSingleCourse(tempSingleCourse));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericCourseFailure(error.message));
      return Promise.reject(error);
    }
  };
};

export const updateSingleCourse = (course: interfaces.ICourse) => {
  return {
    type: CourseActionType.UPDATE_SINGLE_COURSE,
    payload: { course },
  };
};

// Delete Course
export const deleteCourse = (courseId?: interfaces.ID) => {
  return async (dispatch: Dispatch): Promise<any> => {
    if (!courseId) {
      const tempError = 'No ID passed for course, could not delete';
      dispatch(genericCourseFailure(tempError));
      return Promise.reject(tempError);
    }

    dispatch(fetchSingleCourseInit());
    try {
      const deletedCourseId = await deleteCourseAPI(courseId);
      dispatch(deleteSingleCourse(deletedCourseId));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericCourseFailure(error.message));
      return Promise.reject(error);
    }
  };
};

const deleteSingleCourse = (courseId: interfaces.ID) => {
  return {
    type: CourseActionType.DELETE_SINGLE_COURSE,
    payload: { id: courseId },
  };
};

// Reorder Courses
export const reorderCourses = (
  courseId: interfaces.ID,
  newIndex: number,
  previousIndex: number
) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingCourses = getState().courses.courses;

    const reorderedCourses = Array.from(tempExistingCourses);
    reorderedCourses.splice(previousIndex, 1);
    reorderedCourses.splice(newIndex, 0, tempExistingCourses[previousIndex]);

    const coursesInNewOrder = reorderedCourses.map((tempCourse, index) => ({
      ...tempCourse,
      order: index,
    }));

    // * Optimistic update
    dispatch(reorderCoursesSuccess(coursesInNewOrder));

    // * API Call
    try {
      const tempReorderCoursesObject = coursesInNewOrder.map((tempCourse) => ({
        order: tempCourse.order,
        id: tempCourse.id!,
      }));

      const reorderCouresResponse = await reorderCoursesAPI(tempReorderCoursesObject);
      console.log({ reorderCouresResponse });
    } catch (error: any) {
      dispatch(genericCourseFailure(error?.message || error));
    }
  };
};

const reorderCoursesSuccess = (courses: interfaces.ICourse[]) => {
  return {
    type: CourseActionType.REORDER_COURSES_SUCCESS,
    payload: { courses },
  };
};

const genericCourseFailure = (errorMessage: string) => {
  return {
    type: CourseActionType.GENERIC_COURSE_FAILURE,
    payload: { errorMessage },
  };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, interfaces, DataField } from '../../common';
import { Status } from '../../constants';
import { fetchAllUsers } from '../actions';

export const AllUsers = () => {
  const { dashboard }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { status, allUsers, error } = dashboard.allUsers;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Total Users</h4>
        <h3>{status === Status.Loading ? `—` : allUsers.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

import { api } from './api';
import { interfaces } from '../common';

export const getAudioAssets = async (): Promise<interfaces.IAudioAsset[]> => {
  try {
    const tempAudioAssetResponse = await api.get(`/api/v1/audio`);

    return tempAudioAssetResponse.data.audioAssets;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getAudioAssetById = async (id: interfaces.ID): Promise<interfaces.IAudioAsset> => {
  try {
    const tempAudioAssetResponse = await api.get(`/api/v1/audio/asset?id=${id}`);

    return tempAudioAssetResponse.data.audioAsset;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

// api/v1/analytics/trackData/
export const getAudioAssetAnalyticsById = async (
  id: interfaces.ID
): Promise<interfaces.ITrackAnalyticsData> => {
  try {
    const tempTrackAnalyticsDataResponse = await api.get(`/api/v1/analytics/trackData/${id}`);

    return tempTrackAnalyticsDataResponse.data.trackData;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const createAudioAsset = async (
  audioAsset: interfaces.IAudioAsset
): Promise<interfaces.IAudioAsset> => {
  const tempFormData = generateFormDataFromAudioAsset(audioAsset);

  try {
    const createAudioAssetResponse = await api.post(`/api/v1/audio`, tempFormData);
    return createAudioAssetResponse.data.audioAsset;
  } catch (error: any) {
    console.log('error caught — ');
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const editAudioAsset = async (
  audioAsset: interfaces.IAudioAsset
): Promise<interfaces.IAudioAsset> => {
  const tempFormData = generateFormDataFromAudioAsset(audioAsset);

  try {
    const tempEditAudioAssetResponse = await api.put(
      `/api/v1/audio/asset?id=${audioAsset.id}`,
      tempFormData
    );

    return tempEditAudioAssetResponse.data.audioAsset;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const deleteAudioAsset = async (audioAssetId: interfaces.ID) => {
  try {
    const tempDeleteAssetResponse = await api.delete(`/api/v1/audio/asset/${audioAssetId}`);
    return tempDeleteAssetResponse.data.audioAssetId;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

const generateFormDataFromAudioAsset = (audioAsset: interfaces.IAudioAsset): FormData => {
  const formData = new FormData();
  formData.append('title', audioAsset.title);
  formData.append('desc', audioAsset.desc ? audioAsset.desc : '');
  formData.append('level', audioAsset.level.toString());
  formData.append('isPublished', JSON.stringify(audioAsset.isPublished));

  if (audioAsset.order) {
    formData.append('order', audioAsset.order.toString());
  }

  if (audioAsset.type) {
    formData.append('type', audioAsset.type);
  }

  if (audioAsset.moduleId) {
    formData.append('moduleId', audioAsset.moduleId);
  }

  if (audioAsset.file) {
    formData.append('audioAsset', audioAsset.file);
  }

  return formData;
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from '@reach/router';

import { PageHeader, PageSection, Button, ErrorDisplay } from '../../common';
import { MainRoutes, SubRoutes } from '../../constants';
import { IModuleStore } from '../reducers';
import { fetchModules } from '../actions';
import { ModuleList } from '../components';

export const AllModules = (props: RouteComponentProps) => {
  const moduleState: IModuleStore = useSelector(
    (state: { modules: IModuleStore }) => state.modules
  );
  const { modules, isLoading, error } = moduleState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModules());
  }, [dispatch]);

  return (
    <PageSection>
      <PageHeader>
        <h1>{MainRoutes.Modules.name}</h1>
        <Link to={`${SubRoutes.RelativeAdd}`}>
          <Button onClick={null}>Add</Button>
        </Link>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      {isLoading ? <div>Loading...</div> : <ModuleList modules={modules} />}
    </PageSection>
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, DragStart } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { interfaces, DndTypes } from '../../common';
import { KeyPrefix } from '../../constants';
import { reorderCourses } from '../actions';
import { CourseItemWithModules } from './CourseItemWithModules';
import { reorderModules } from '../../modules';
import { moveModuleToEmptyCourse } from '../../modules/actions';

const CourseListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 12px;
`;

interface IProps {
  courses: interfaces.ICourse[];
}

export const CourseList = ({ courses }: IProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result;

    console.log({ type, destination, source, draggableId });

    if (!destination) {
      return;
    }

    if (destination.droppableId.includes(KeyPrefix.EmptyCourse)) {
      const moduleId = source.droppableId;
      const previousIndex = source.index;
      const destinationCourseId = destination.droppableId.replace(KeyPrefix.EmptyCourse, '');

      dispatch(moveModuleToEmptyCourse({ moduleId, destinationCourseId, previousIndex }));
      return;
    }

    if (type === DndTypes.Course) {
      const courseId = source.droppableId;
      const newIndexOfCourse = destination.index;
      const previousIndexOfCourse = source.index;

      console.log({ type });
      dispatch(reorderCourses(courseId, newIndexOfCourse, previousIndexOfCourse));
    }

    if (type === DndTypes.Module) {
      const moduleId = source.droppableId;
      const distinationModuleId = destination.droppableId;
      const newIndexOfModule = destination.index;
      const previousIndexOfModule = source.index;

      dispatch(
        reorderModules({
          moduleId,
          distinationModuleId,
          newIndex: newIndexOfModule,
          previousIndex: previousIndexOfModule,
        })
      );
    }

    setIsDragging(false);
  };

  const onDragStart = (start: DragStart) => {
    // TODO temp hack to keep the empty course dropzone from showing when
    // TODO a course is selected
    if (start.type === DndTypes.Module) {
      setIsDragging(true);
    }
  };

  return courses.length > 0 ? (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <CourseListWrapper>
        {courses.map((tempCourse: interfaces.ICourse, index: number) => (
          <Droppable
            droppableId={tempCourse.id as string}
            key={tempCourse.id}
            type={DndTypes.Course}
          >
            {(provided) => (
              // ? TODO Maybe move droppable into the CourseItemWithModules component
              <CourseItemWithModules
                provided={provided}
                key={tempCourse.id}
                course={tempCourse}
                index={index}
                isDragging={isDragging}
              />
            )}
          </Droppable>
        ))}
      </CourseListWrapper>
    </DragDropContext>
  ) : (
    <div>No courses found...</div>
  );
};

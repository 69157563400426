import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { interfaces } from '../../common';

const progressBarBorderRadius = '12px';

const CoursePreviewContainer = styled.div`
  height: 450px;
  width: 280px;
  display: flex;
  color: white;
  border-radius: 16px;
  background-size: cover;
  box-shadow: 0px 4px 4px 1px #333333a6;
  margin-bottom: 6px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  background: linear-gradient(#33333300, #333333a6, #333333ff);
  border-radius: 16px;
`;

const CompletedStatus = styled.div`
  margin-bottom: auto;
  margin-left: auto;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: bold;
  text-shadow: 0px 3px 6px #49a4dbc9;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const ContentDuration = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
`;

const Description = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 11px;
  margin-bottom: 12px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 24px;
  border-radius: ${progressBarBorderRadius};
  background-color: white;
  box-shadow: 0 5px 5px #00000017;
`;

interface IProgressBarProps {
  progress: number;
}

const ProgressBar = styled.div<IProgressBarProps>`
  width: ${({ progress = 9 }) => `${progress}%`};
  height: 100%;
  border-top-left-radius: ${progressBarBorderRadius};
  border-bottom-left-radius: ${progressBarBorderRadius};
  background: linear-gradient(90deg, #49a4db, 45%, #8e0089cc, 90%, transparent);
`;

interface IProps {
  course: interfaces.ICourse;
  imageFile?: any | null;
}

export const CoursePreview = ({ course, imageFile }: IProps) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    // minimum is 20%, max is 90%
    setProgress(Math.floor(Math.random() * 70) + 20);
  }, []);

  return (
    <CoursePreviewContainer
      style={{
        backgroundImage: course.imageUrl
          ? `url(${course.imageUrl})`
          : imageFile
          ? `url(${URL.createObjectURL(imageFile)})`
          : `url('')`,
        backgroundSize: 'cover',
      }}
    >
      <ContentContainer>
        {/* Completed Status currently has nothing - saving for positioning */}
        <CompletedStatus />
        <ContentDuration>
          {course.averageTrackLength ? `${course.averageTrackLength} exercises` : ''}
        </ContentDuration>
        <Title>{course.title}</Title>
        <Description>{course.desc}</Description>
        <ProgressBarContainer>
          <ProgressBar progress={progress} />
        </ProgressBarContainer>
      </ContentContainer>
    </CoursePreviewContainer>
  );
};

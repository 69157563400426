import React, { ChangeEvent } from 'react';

import { Select, Option } from '../../common';
import { AudioAssetType } from '../constants';

interface IProps {
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

export const AudioTypeSelect = ({
  value = AudioAssetType.EXERCISE,
  onChange,
  disabled,
}: IProps) => {
  return (
    <Select value={value} onChange={onChange} disabled={disabled}>
      <Option key="exercise" value={AudioAssetType.EXERCISE}>
        {AudioAssetType.EXERCISE}
      </Option>
      <Option key="discourse" value={AudioAssetType.DISCOURSE}>
        {AudioAssetType.DISCOURSE}
      </Option>
    </Select>
  );
};

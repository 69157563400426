import { interfaces } from '../common';
import { UserActionType } from './constants';

export interface IUserStore {
  user: interfaces.IUser | null;
  error: string;
  isLoading: boolean;
}

const initialUserState: IUserStore = {
  user: null,
  error: '',
  isLoading: false,
};

export const userReducer = (
  state = initialUserState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case UserActionType.LOGIN_USER:
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    case UserActionType.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...payload.user },
      };
    case UserActionType.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.errorMessage,
      };
    case UserActionType.SET_USER:
      return { user: { ...state.user, ...payload.user } };
    case UserActionType.CLEAR_USER:
      return { ...initialUserState };
    default:
      return state;
  }
};

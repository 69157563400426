import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { interfaces } from './';
import { theme as friendzoneTheme } from './styles/theme';

// Normal Input
export const Input = styled.input`
  padding: 12px;
  border-radius: ${({ theme }: interfaces.IStyledComponent) => theme.defaultBorderRadius};
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  margin-bottom: 6px;

  ::placeholder {
    color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.placeholder};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.disabledText};
    background-color: ${({ theme }) => theme.colors.disabledBackground};
  }
`;

// Textarea Input
export const TextAreaInput = styled.textarea`
  padding: 12px;

  border-radius: ${({ theme }: interfaces.IStyledComponent) => theme.defaultBorderRadius};
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  margin-bottom: 6px;
  resize: none;

  ::placeholder {
    color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.placeholder};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.disabledText};
    background-color: ${({ theme }) => theme.colors.disabledBackground};
  }
`;

// Password Input
const PasswordInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.black};
  background-color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.white};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  padding: 12px;
  border-radius: ${({ theme }: interfaces.IStyledComponent) => theme.defaultBorderRadius};
  margin-bottom: 6px;
`;

const PasswordEyeButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const PasswordInputNoBorder = styled.input`
  flex: 1;
  border: none;
  font-size: 1rem;
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
`;

export const PasswordInput = ({ value, onChange }: any) => {
  const [isPasswordMasked, setIsPasswordMasked] = useState<boolean>(true);
  return (
    <PasswordInputWrapper>
      <PasswordInputNoBorder
        value={value}
        onChange={onChange}
        type={isPasswordMasked ? 'password' : 'text'}
      />
      <PasswordEyeButton
        onClick={() => setIsPasswordMasked(!isPasswordMasked)}
        onKeyPress={(event: React.KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
          setIsPasswordMasked(!isPasswordMasked);
        }}
      >
        <FontAwesomeIcon
          icon={isPasswordMasked ? faEye : faEyeSlash}
          color={friendzoneTheme.colors.black}
        />
      </PasswordEyeButton>
    </PasswordInputWrapper>
  );
};

export const Select = styled.select`
  cursor: pointer;

  padding: 12px;
  border-radius: ${({ theme }: interfaces.IStyledComponent) => theme.defaultBorderRadius};
  margin-bottom: 6px;

  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  /* remove dropdown for now */
  appearance: none;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.disabledText};
    background-color: ${({ theme }) => theme.colors.disabledBackground};
  }
`;

export const Option = styled.option`
  cursor: pointer;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.defaultFontColor};
`;

// @ts-ignore - weird cyclical issue with TS and Styled Components
const StyledCheckbox = styled.input.attrs((props) => ({ type: 'checkbox' }))`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

export const Checkbox = ({
  label,
  value,
  onChange,
  disabled,
}: {
  label: string;
  value: boolean;
  onChange: any;
  disabled?: boolean;
}) => {
  return (
    <CheckboxGroup>
      <StyledCheckbox checked={value} onChange={onChange} disabled={disabled} />
      <InputLabel style={{ cursor: 'pointer' }}>{label}</InputLabel>
    </CheckboxGroup>
  );
};

export enum AudioAssetActionType {
  FETCH_AUDIO_ASSETS = 'FETCH_AUDIO_ASSETS',
  FETCH_AUDIO_ASSETS_SUCCESS = 'FETCH_AUDIO_ASSETS_SUCCESS',
  ADD_SINGLE_AUDIO_ASSET = 'ADD_SINGLE_AUDIO_ASSET',
  UPDATE_SINGLE_AUDIO_ASSET = 'UPDATE_SINGLE_AUDIO_ASSET',
  AUDIO_ASSET_GENERIC_FAILURE = 'AUDIO_ASSET_GENERIC_FAILURE',
  DELETE_SINGLE_AUDIO_ASSET = 'DELETE_SINGLE_AUDIO_ASSET',
  FETCH_AUDIO_ASSET_ANALYTICS_BY_ID = 'FETCH_AUDIO_ASSET_ANALYTICS_BY_ID',
  FETCH_AUDIO_ASSET_ANALYTICS_BY_ID_SUCCESS = 'FETCH_AUDIO_ASSET_ANALYTICS_BY_ID_SUCCESS',
}

export enum AudioAssetType {
  EXERCISE = 'EXERCISE',
  DISCOURSE = 'DISCOURSE',
}

import { interfaces } from '../common';
import { CourseActionType } from './constants';

export interface ICourseStore {
  courses: interfaces.ICourse[];
  lastUpdated: number;
  error: string;
  isLoading: boolean;
}

const initialCourseState: ICourseStore = {
  courses: [],
  lastUpdated: 0,
  error: '',
  isLoading: false,
};

export const courseReducer = (
  state = initialCourseState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case CourseActionType.FETCH_COURSES:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case CourseActionType.FETCH_COURSES_SUCCESS:
      return {
        ...state,
        lastUpdated: Date.now(),
        isLoading: false,
        courses: [...payload.courses],
      };
    case CourseActionType.FETCH_SINGLE_COURSE:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case CourseActionType.UPDATE_SINGLE_COURSE:
      return {
        ...state,
        isLoading: false,
        error: '',
        courses: [
          ...state.courses.map((tempCourse: interfaces.ICourse) => {
            if (tempCourse.id === payload.course.id) {
              return { ...payload.course, lastUpdated: Date.now() };
            }

            return tempCourse;
          }),
        ],
      };
    case CourseActionType.ADD_SINGLE_COURSE:
      return {
        ...state,
        isLoading: false,
        error: '',
        courses: [...state.courses, { ...payload.course, lastUpdated: Date.now() }],
      };
    case CourseActionType.DELETE_SINGLE_COURSE:
      return {
        ...state,
        isLoading: false,
        error: '',
        courses: state.courses.filter(
          (tempCourse: interfaces.ICourse) => tempCourse.id !== payload.id
        ),
      };
    case CourseActionType.REORDER_COURSES_SUCCESS:
      return { ...state, courses: [...payload.courses] };
    case CourseActionType.GENERIC_COURSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.errorMessage,
      };
    default:
      return { ...state };
  }
};

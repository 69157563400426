import React, { ReactNode, ButtonHTMLAttributes, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { interfaces } from './';

interface IStyledButton extends interfaces.IStyledComponent, ButtonHTMLAttributes<HTMLElement> {
  onClick: any;
  children: ReactNode;
  isLoading?: boolean;
}

const StyledButton = ({ isLoading, children, onClick, ...rest }: IStyledButton) => (
  <button onClick={onClick} {...rest}>
    {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
  </button>
);

export const Button = styled(StyledButton)`
  cursor: pointer;
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  background-color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.offWhite};
  border: none;

  padding: 12px;
  border-radius: ${({ theme }: interfaces.IStyledComponent) => theme.defaultBorderRadius};
  border-bottom: 2px solid darkblue;

  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.disabled}`};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.primarySelected};
    border-bottom: 0px solid lightblue;
  }

  :active {
    background-color: ${({ theme }) => theme.colors.primaryActivated};
    transform: translateY(2px);
  }
`;

export const TextButton = styled(StyledButton)`
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};

  p {
    text-decoration: underline;
  }
`;

export const DangerButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.error};
  border-bottom: 2px solid darkred;

  :focus {
    background-color: pink;
    border-bottom: 2px solid #fa6639;
  }

  :active {
    background-color: darkred;
    transform: translateY(2px);
  }
`;

interface ILinkButtonProps {
  to: string;
  children: React.ReactNode;
  style?: any;
}

export const LinkButton = ({ to, children, style }: ILinkButtonProps) => {
  return (
    <Link to={to} style={{ ...style }}>
      <Button onClick={null}>{children}</Button>
    </Link>
  );
};

interface IChevronButtonProps {
  isExpanded?: boolean;
  isLoading?: boolean;
  onClick: any;
}

const StyledChevronBaseButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  width: 16px;
  margin: 0px 3px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ChevronButton = ({ isExpanded, isLoading, onClick }: IChevronButtonProps) => {
  const theme = useContext(ThemeContext);

  return isLoading ? (
    <FontAwesomeIcon
      style={{ marginRight: 3, padding: 3 }}
      color={theme.colors.primary}
      icon={faSpinner}
      spin
    />
  ) : (
    <StyledChevronBaseButton onClick={onClick} tabIndex={0}>
      <FontAwesomeIcon size="1x" icon={isExpanded ? faChevronDown : faChevronRight} />
    </StyledChevronBaseButton>
  );
};

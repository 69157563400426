import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';

import { ErrorDisplay, ListField, interfaces } from '../../common';
import { fetchTrendingMonthlyTrackData } from '../actions';
import { Status, MainRoutes, SubRoutes } from '../../constants';

export const TrendingMonthlyTracks = () => {
  const { dashboard }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { data, status, error } = dashboard.trackMonthlyTrending;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTrendingMonthlyTrackData());
  }, [dispatch]);

  return (
    <div>
      <ListField>
        <h4>Monthly Trending Tracks</h4>
        {status === Status.Loading ? (
          <h3>—</h3>
        ) : (
          <ol>
            {data.map((track, index) => (
              <li key={index}>
                <Link to={`${MainRoutes.AudioAssets.path}/${SubRoutes.AudioAsset}/${track.id}`}>
                  {`${track.title}  (${track.playCount})`}
                </Link>
              </li>
            ))}
          </ol>
        )}
      </ListField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

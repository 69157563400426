import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import { interfaces, DndTypes } from '../../common';
import { ModuleItemDnD } from './ModuleItemDnD';
import { KeyPrefix } from '../../constants';

const ModuleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

interface IProps {
  modules?: interfaces.IModule[];
  courseId: string; // used as an ID for the droppable
  isDraggingInProgress?: boolean;
}

export const ModuleListDnD = ({ modules, courseId, isDraggingInProgress }: IProps) => {
  const isEmptyCourse = modules?.length === 0;

  // * This is to provide a dropzone for the course even if there are no modules
  if (isEmptyCourse) {
    modules.push({
      id: `${KeyPrefix.EmptyCourse}${courseId}`,
    } as interfaces.IModule);
  }

  return modules ? (
    <ModuleListWrapper>
      {modules.map((tempModule: interfaces.IModule, moduleIndex: number) => {
        return (
          <Droppable
            droppableId={tempModule.id as string}
            key={tempModule.id}
            type={DndTypes.Module}
          >
            {(provided, snapshot) => {
              return (
                <ModuleItemDnD
                  key={tempModule.id}
                  module={tempModule}
                  index={moduleIndex}
                  provided={provided}
                  isDraggingOver={snapshot.isDraggingOver}
                  isDraggingInProgress={isDraggingInProgress}
                >
                  {provided.placeholder}
                </ModuleItemDnD>
              );
            }}
          </Droppable>
        );
      })}
    </ModuleListWrapper>
  ) : null;
};

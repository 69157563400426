import { Dispatch } from 'redux';
import { login, setToken } from '../api';
import { interfaces } from '../common';
import { saveUserInLocalStorage, clearUserFromLocalStorage, isAdmin } from '../utils';
import { UserActionType } from './constants';

interface ILoginParams {
  email: string;
  password: string;
}

interface IUserAction {
  type: UserActionType;
  payload: { errorMessage?: string; user?: interfaces.IUser } | null;
}

export const loginUser = ({ email, password }: ILoginParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginInit());
    try {
      const tempLoginResponse = await login({ email, password });
      if (!isAdmin(tempLoginResponse)) {
        throw new Error("You don't access (user is not an admin).");
      }
      dispatch(loginSuccess(tempLoginResponse));
    } catch (error: any) {
      dispatch(loginFailure(error.message));
    }
  };
};

const loginInit = (): IUserAction => {
  return {
    type: UserActionType.LOGIN_USER,
    payload: null,
  };
};

const loginSuccess = (user: interfaces.IUser): IUserAction => {
  if (user) {
    saveUserInLocalStorage(user);
    setToken(user.token);
  }

  return {
    type: UserActionType.LOGIN_USER_SUCCESS,
    payload: { user },
  };
};

const loginFailure = (errorMessage: string): IUserAction => {
  return {
    type: UserActionType.LOGIN_USER_FAILURE,
    payload: { errorMessage },
  };
};

export const setUser = (user: interfaces.IUser): IUserAction => {
  if (user) {
    saveUserInLocalStorage(user);
    setToken(user.token);
  }

  return {
    type: UserActionType.SET_USER,
    payload: { user },
  };
};

export const clearUser = (): IUserAction => {
  clearUserFromLocalStorage();
  return {
    type: UserActionType.CLEAR_USER,
    payload: null,
  };
};

import React, { ChangeEvent } from 'react';

import {
  interfaces,
  Input,
  InputGroup,
  InputLabel,
  TextAreaInput,
  PageForm,
  Checkbox,
  Row,
  DangerButton,
} from '../../common';
import { CourseSelect } from '../../courses';

interface IProps {
  isEditMode: boolean;
  module: interfaces.IModule | null;
  onUpdateForm: (updatedField: { [key: string]: any }) => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const ModuleForm = ({ module, onSubmit, onDelete, onUpdateForm, isEditMode }: IProps) => {
  return (
    module && (
      <PageForm onSubmit={onSubmit}>
        <Row style={{ marginBottom: 6, height: 46 }}>
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onUpdateForm({ isPublished: !module.isPublished });
            }}
            label="Published"
            value={module.isPublished || false}
            disabled={!isEditMode}
          />
          {isEditMode && module.id && !module.isPublished && (
            <DangerButton
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                if (onDelete) {
                  onDelete();
                }
              }}
              style={{ marginLeft: 12 }}
            >
              Delete?
            </DangerButton>
          )}
        </Row>
        <Input
          placeholder="Module Title"
          value={module.title}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onUpdateForm({ title: event.target.value })
          }
          disabled={!isEditMode}
        />
        <CourseSelect
          value={(module.courseId as string) || ''}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            onUpdateForm({ courseId: event.target.value })
          }
          disabled={!isEditMode}
        />
        <TextAreaInput
          placeholder="Module Description"
          value={module.desc || ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onUpdateForm({ desc: event.target.value })
          }
          disabled={!isEditMode}
        />
        <Row style={{ marginTop: 8, marginBottom: 8 }}>
          <InputGroup>
            <InputLabel>Level</InputLabel>
            <Input
              type="number"
              placeholder="Level in Module"
              value={module.level || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ level: event.target.value })
              }
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Order in Course (or Library)</InputLabel>
            <Input
              type="number"
              placeholder="Order in Module"
              value={module.order || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ order: event.target.value })
              }
            />
          </InputGroup>
        </Row>
      </PageForm>
    )
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, interfaces } from '../../common';
import { fetchWeeklyUsers } from '../actions';
import { Status } from '../../constants';

export const WeeklyUsers = () => {
  const { dashboard }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { weeklyUsers, status, error } = dashboard.weeklyActiveUsers;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWeeklyUsers());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Weekly Active Users</h4>
        <h3>{status === Status.Loading ? `—` : weeklyUsers.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

import { api } from './api';
import { interfaces } from '../common';

export const login = async ({
  email,
  password,
}: interfaces.ILoginParams): Promise<interfaces.IUser> => {
  const tempEmail = email.trim();

  try {
    const tempUserResponse = await api.post(`/api/v1/users/authenticate`, {
      email: tempEmail.toLocaleLowerCase(),
      password,
    });

    return tempUserResponse.data.user;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

import { Dispatch } from 'redux';

import { AudioAssetActionType } from './constants';
import { interfaces } from '../common';
import { Time, isOlderThan } from '../utils';
import {
  getAudioAssets,
  getAudioAssetById,
  createAudioAsset as createAudioAssetAPI,
  editAudioAsset as editAudioAssetAPI,
  deleteAudioAsset as deleteAudioAssetAPI,
  getAudioAssetAnalyticsById as getAudioAssetAnalyticsByIdAPI,
} from '../api';

export const fetchAudioAssets = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().audioAssets.lastUpdated;
    if (!isOlderThan(Time.OneMinute, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchAudioAssetsInit());
    try {
      const tempAudioAssets = await getAudioAssets();
      dispatch(fetchAudioAssetsSuccess(tempAudioAssets));
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
    }
  };
};

const fetchAudioAssetsInit = () => {
  return {
    type: AudioAssetActionType.FETCH_AUDIO_ASSETS,
    payload: null,
  };
};

const fetchAudioAssetsSuccess = (audioAssets: interfaces.IAudioAsset[]) => {
  return {
    type: AudioAssetActionType.FETCH_AUDIO_ASSETS_SUCCESS,
    payload: { audioAssets },
  };
};

// Create Audio Asset
export const createAudioAsset = (audioAsset: interfaces.IAudioAsset) => {
  return async (dispatch: Dispatch): Promise<any> => {
    // using this mostly just so setting "isLoading" - consider making a generic for this
    dispatch(fetchAudioAssetsInit());
    try {
      const tempSingleAudioAsset = await createAudioAssetAPI(audioAsset);
      dispatch(addSingleAudioAsset(tempSingleAudioAsset));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
      return Promise.reject(error);
    }
  };
};

// Single Audio Asset
export const fetchSingleAudioAsset = (id: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingAudioAssets = getState().audioAssets.audioAssets;
    const tempSingleExistingAudioAsset = tempExistingAudioAssets.find(
      (tempAudioAsset: interfaces.IAudioAsset) => tempAudioAsset.id === id
    );

    try {
      if (tempSingleExistingAudioAsset) {
        if (isOlderThan(Time.OneMinute, tempSingleExistingAudioAsset.lastUpdated)) {
          // Refresh as it's out of date
          dispatch(fetchAudioAssetsInit());
          const tempSingleAudioAsset = await getAudioAssetById(id);
          dispatch(updateSingleAudioAsset(tempSingleAudioAsset));
        }
      } else {
        // Doesnt exist at all, get a new version
        dispatch(fetchAudioAssetsInit());
        const tempSingleAudioAsset = await getAudioAssetById(id);
        dispatch(addSingleAudioAsset(tempSingleAudioAsset));
      }
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
    }
  };
};

// Edit Audio Asset
export const editAudioAsset = (audioAsset: interfaces.IAudioAsset) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(fetchAudioAssetsInit());
    try {
      const tempSingleAudioAsset = await editAudioAssetAPI(audioAsset);
      dispatch(updateSingleAudioAsset(tempSingleAudioAsset));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
      return Promise.reject(error);
    }
  };
};

// Delete Audio Asset
export const deleteAudioAsset = (audioAssetId?: interfaces.ID) => {
  return async (dispatch: Dispatch): Promise<any> => {
    if (!audioAssetId) {
      const tempError = 'No ID passed for AudioAsset, could not delete';
      dispatch(genericAudioAssetFailure(tempError));
      return Promise.reject(tempError);
    }

    dispatch(fetchAudioAssetsInit());
    try {
      const deletedAudioAssetId = await deleteAudioAssetAPI(audioAssetId);
      dispatch(deleteSingleAudioAsset(deletedAudioAssetId));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
      return Promise.reject(error);
    }
  };
};

const deleteSingleAudioAsset = (audioAssetId: interfaces.ID) => {
  return {
    type: AudioAssetActionType.DELETE_SINGLE_AUDIO_ASSET,
    payload: { id: audioAssetId },
  };
};

const updateSingleAudioAsset = (audioAsset: interfaces.IAudioAsset) => {
  return {
    type: AudioAssetActionType.UPDATE_SINGLE_AUDIO_ASSET,
    payload: { audioAsset },
  };
};

const addSingleAudioAsset = (audioAsset: interfaces.IAudioAsset) => {
  return {
    type: AudioAssetActionType.ADD_SINGLE_AUDIO_ASSET,
    payload: { audioAsset },
  };
};

export const fetchAudioAssetAnalyticsById = (id: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempAssetAnalytics = getState().audioAssets.audioAssetAnalytics[id];

    if (tempAssetAnalytics && !isOlderThan(Time.OneMinute, tempAssetAnalytics.lastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchAudioAssetsAnalyticsByIdInit(id));
    try {
      const tempAnalyticsData = await getAudioAssetAnalyticsByIdAPI(id);
      dispatch(fetchAudioAssetAnalyticsByIdSuccess(id, tempAnalyticsData));
    } catch (error: any) {
      dispatch(genericAudioAssetFailure(error.message));
    }
  };
};

// TODO implement this
const fetchAudioAssetsAnalyticsByIdInit = (id: interfaces.ID) => {
  return {
    type: AudioAssetActionType.FETCH_AUDIO_ASSET_ANALYTICS_BY_ID,
    payload: { id },
  };
};

// TODO implement this
const fetchAudioAssetAnalyticsByIdSuccess = (
  id: interfaces.ID,
  trackAnalyticData: interfaces.ITrackAnalyticsData
) => {
  return {
    type: AudioAssetActionType.FETCH_AUDIO_ASSET_ANALYTICS_BY_ID_SUCCESS,
    payload: { id, ...trackAnalyticData },
  };
};

const genericAudioAssetFailure = (errorMessage: string) => {
  return {
    type: AudioAssetActionType.AUDIO_ASSET_GENERIC_FAILURE,
    payload: { errorMessage },
  };
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from '@reach/router';

import { MainRoutes, SubRoutes } from '../../constants';
import {
  PageSection,
  PageHeader,
  Button,
  ErrorDisplay,
  interfaces,
  CalloutText,
} from '../../common';
import { AudioAssetList } from '../components';
import { fetchAudioAssets } from '../actions';

export const AllAudioAssets = (props: RouteComponentProps) => {
  const { audioAssets, isLoading, error } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.audioAssets
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAudioAssets());
  }, [dispatch]);

  return (
    <PageSection>
      <PageHeader>
        <h1>{MainRoutes.AudioAssets.name}</h1>
        <Link to={`${SubRoutes.RelativeAdd}`}>
          <Button onClick={null}>Add</Button>
        </Link>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      {isLoading ? (
        <CalloutText>Loading...</CalloutText>
      ) : (
        <AudioAssetList audioAssets={audioAssets} />
      )}
    </PageSection>
  );
};

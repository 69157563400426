// All Time is in Milliseconds
export enum Time {
  FiveSeconds = 5 * 1000,
  TenSeconds = 10 * 1000,
  ThirtySeconds = 30 * 1000,
  OneMinute = 60 * 1000,
  FiveMinutes = 5 * 60 * 1000,
  TenMinutes = 10 * 60 * 1000,
}

export const isOlderThan = (time: Time, currentValue?: number) => {
  if (!currentValue) {
    return true;
  }

  if (currentValue < Date.now() - time) {
    return true;
  }

  return false;
};

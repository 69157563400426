import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import { MainRoutes } from '../../constants';
import { AudioAssetType } from '../constants';
import { PageSection, PageHeader, Button, ErrorDisplay, interfaces } from '../../common';
import { AudioAssetForm } from '../components';
import { createAudioAsset } from '../actions';

interface IProps extends RouteComponentProps {
  moduleId?: string;
}

export const AddAudioAsset = ({ moduleId = '' }: IProps) => {
  const { isLoading, error } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.audioAssets
  );
  const dispatch = useDispatch();
  const [audioAsset, setAudioAsset] = useState<interfaces.IAudioAsset>({
    title: '',
    desc: '',
    order: 0,
    level: 0,
    url: null,
    type: AudioAssetType.EXERCISE,
    isPublished: true,
    moduleId,
  });

  const onUpdateAudioAsset = (updatedField: { [key: string]: any }) => {
    setAudioAsset({ ...audioAsset, ...updatedField });
  };

  const onCreateAudioAsset = async () => {
    try {
      await dispatch(createAudioAsset(audioAsset));
      navigate(MainRoutes.AudioAssets.path);
    } catch (error: any) {
      console.log('error creating course — ', error);
    }
  };

  const isValid = () => {
    if (!audioAsset.title || !audioAsset.file) {
      return false;
    }

    return true;
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>Add Audio Asset</h1>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <AudioAssetForm
        audioAsset={audioAsset}
        isEditMode={true}
        onSubmit={onCreateAudioAsset}
        onUpdateForm={onUpdateAudioAsset}
      />
      <Button onClick={onCreateAudioAsset} disabled={!isValid()} isLoading={isLoading}>
        Save
      </Button>
    </PageSection>
  );
};

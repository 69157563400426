import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, interfaces, DataField } from '../../common';
import { fetchAnnouncements } from '../../announcements/actions';

export const AnnouncementContent = () => {
  const { announcements }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { announcements: allAnnouncements, error, isLoading } = announcements;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Announcements</h4>
        <h3>{isLoading ? `—` : allAnnouncements.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

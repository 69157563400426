import { Dispatch } from 'redux';
import { ModuleActionType } from './constants';

import {
  getModules,
  getModuleById,
  getModulesByCourseId,
  createModule as createModuleAPI,
  editModule as editModuleAPI,
  deleteModule as deleteModuleAPI,
  reorderModules as reorderModulesAPI,
} from '../api';
import { interfaces } from '../common';
import { Time, isOlderThan } from '../utils';

export const fetchModules = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().modules.lastUpdated;
    if (!isOlderThan(Time.OneMinute, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchModulesInit());
    try {
      const tempModules = await getModules();
      dispatch(fetchModulesSuccess(tempModules));
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
    }
  };
};

const fetchModulesInit = () => {
  return {
    type: ModuleActionType.FETCH_MODULES,
    payload: null,
  };
};

const fetchModulesSuccess = (modules: interfaces.IModule[]) => {
  return {
    type: ModuleActionType.FETCH_MODULES_SUCCESS,
    payload: { modules },
  };
};

// Single Course
export const fetchSingleModule = (id: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingModules = getState().modules.modules;
    const tempSingleExistingModule = tempExistingModules.find(
      (tempModule: interfaces.IModule) => tempModule.id === id
    );

    try {
      if (tempSingleExistingModule) {
        if (isOlderThan(Time.OneMinute, tempSingleExistingModule.lastUpdated)) {
          // Refresh as it's out of date
          dispatch(fetchModulesInit());
          const tempSingleModule = await getModuleById(id);
          dispatch(updateSingleModule(tempSingleModule));
        }
      } else {
        // Doesnt exist at all, get a new version
        dispatch(fetchModulesInit());
        const tempSingleModule = await getModuleById(id);
        dispatch(addSingleModule(tempSingleModule));
      }
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
    }
  };
};

export const fetchCourseModuleById = (id?: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    // TODO Caching check here

    dispatch(fetchModulesByCourseIdInit());
    try {
      if (!id) {
        dispatch(fetchModulesByCourseIdSuccess('', []));
        console.warn('No course id provided! Could not fetch modules');
        return;
      }

      const tempModules = await getModulesByCourseId(id);
      // console.log('tempModules', id, tempModules);
      dispatch(fetchModulesByCourseIdSuccess(id, tempModules));
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
    }
  };
};

const fetchModulesByCourseIdInit = () => {
  return {
    type: ModuleActionType.FETCH_MODULES_BY_COURSE_ID,
    payload: null,
  };
};

const fetchModulesByCourseIdSuccess = (id: interfaces.ID, modules: interfaces.IModule[]) => {
  const courseModules = modules.map((tempModule) => {
    return { ...tempModule, courseId: id };
  });

  return {
    type: ModuleActionType.FETCH_MODULES_BY_COURSE_ID_SUCCESS,
    payload: { modules: courseModules },
  };
};

// Create Module
export const createModule = (module: interfaces.IModule) => {
  return async (dispatch: Dispatch): Promise<any> => {
    // using this mostly just so setting "isLoading" - consider making a generic for this
    dispatch(fetchModulesInit());
    try {
      const tempSingleModule = await createModuleAPI(module);
      dispatch(addSingleModule(tempSingleModule));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
      return Promise.reject(error);
    }
  };
};

// Edit Module
export const editModule = (module: interfaces.IModule) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(fetchModulesInit());
    try {
      const tempSingleModule = await editModuleAPI(module);
      dispatch(updateSingleModule(tempSingleModule));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
      return Promise.reject(error);
    }
  };
};

// Delete Module
export const deleteModule = (moduleId?: interfaces.ID) => {
  return async (dispatch: Dispatch): Promise<any> => {
    if (!moduleId) {
      const tempError = 'No ID passed for course, could not delete';
      dispatch(genericModuleFailure(tempError));
      return Promise.reject(tempError);
    }

    dispatch(fetchModulesInit());
    try {
      const deletedCourseId = await deleteModuleAPI(moduleId);
      dispatch(deleteSingleModule(deletedCourseId));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericModuleFailure(error.message));
      return Promise.reject(error);
    }
  };
};

const deleteSingleModule = (moduleId: interfaces.ID) => {
  return {
    type: ModuleActionType.DELETE_SINGLE_MODULE,
    payload: { id: moduleId },
  };
};

const addSingleModule = (module: interfaces.IModule) => {
  return {
    type: ModuleActionType.ADD_SINGLE_MODULE,
    payload: { module },
  };
};

const updateSingleModule = (module: interfaces.IModule) => {
  return {
    type: ModuleActionType.UPDATE_SINGLE_MODULE,
    payload: { module },
  };
};

interface IReorderModulesArgs {
  moduleId: interfaces.ID;
  distinationModuleId: interfaces.ID;
  newIndex: number;
  previousIndex: number;
}

export const reorderModules = ({
  moduleId,
  distinationModuleId,
  newIndex,
  previousIndex,
}: IReorderModulesArgs) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingModules = getState().modules.modules;

    const movedModule = tempExistingModules.find((tempModule) => tempModule.id === moduleId);
    const destinationModule = tempExistingModules.find(
      (tempModule) => tempModule.id === distinationModuleId
    );

    if (!movedModule || !destinationModule) {
      console.log('failed to reorder modules', { movedModule, destinationModule });
      // Failure state (dispatch failure)
      return;
    }

    let modulesInNewOrder: interfaces.IModule[] = [];

    // TODO break up these two cases into separate functions?
    if (movedModule.courseId === destinationModule.courseId) {
      console.log('same courses, reordering in place');
      // * Courses are the same, just reorder them
      const modulesOfSameCourse = tempExistingModules.filter(
        (tempModule) => tempModule.courseId === movedModule.courseId
      );

      const modulesInOrder = Array.from(modulesOfSameCourse).sort((a, b) => a.order - b.order);

      modulesInOrder.splice(previousIndex, 1);
      modulesInOrder.splice(newIndex, 0, movedModule);

      modulesInNewOrder = modulesInOrder.map((tempModule, index) => {
        return { ...tempModule, order: index };
      });
      dispatch(reorderModulesSuccess(modulesInNewOrder));
    } else {
      console.log('different courses, doing a lot of work');
      // * Courses are different, move it to the new course and reorder both
      const modulesOfNewCourse = tempExistingModules
        .filter((tempModule) => tempModule.courseId === destinationModule.courseId)
        .sort((a, b) => a.order - b.order);

      const modulesOfPreviousCourse = tempExistingModules
        .filter((tempModule) => tempModule.courseId === movedModule.courseId)
        .sort((a, b) => a.order - b.order);

      const newCourseModulesInOrder = Array.from(modulesOfNewCourse);
      const previousCoursemModulesInOrder = Array.from(modulesOfPreviousCourse);

      // Add to new course in the right spot and remove from previous course where it was
      newCourseModulesInOrder.splice(newIndex, 0, movedModule);
      previousCoursemModulesInOrder.splice(previousIndex, 1);

      // Manually set the order to be 0-n for both courses
      const newCourseModules = newCourseModulesInOrder.map((tempModule, index) => {
        return { ...tempModule, order: index, courseId: destinationModule.courseId };
      });
      const previousCourseModules = previousCoursemModulesInOrder.map((tempModule, index) => {
        return { ...tempModule, order: index, courseId: movedModule.courseId };
      });

      modulesInNewOrder = [...previousCourseModules, ...newCourseModules];

      // * Optimistic response
      dispatch(reorderModulesSuccess(modulesInNewOrder));
    }

    // * One or the other case has executed and we have the new order
    // * API Call
    try {
      const tempReorderModulesRequestObjects = modulesInNewOrder.map((tempModule) => ({
        moduleId: tempModule.id!,
        order: tempModule.order,
        courseId: tempModule.courseId!,
      }));

      const moduleReorderResponse = await reorderModulesAPI(tempReorderModulesRequestObjects);
      dispatch(reorderModulesSuccess(moduleReorderResponse));
    } catch (error: any) {
      dispatch(genericModuleFailure(error?.message || error));
    }
  };
};

interface IMoveModuleToEmptyCourseArgs {
  moduleId: interfaces.ID;
  destinationCourseId: interfaces.ID;
  previousIndex: number;
}

export const moveModuleToEmptyCourse = ({
  moduleId,
  destinationCourseId,
  previousIndex,
}: IMoveModuleToEmptyCourseArgs) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    console.log('move to empty course');
    const tempExistingModules = getState().modules.modules;

    const movedModule = tempExistingModules.find((tempModule) => tempModule.id === moduleId);
    if (!movedModule) {
      // Failure state
      // TODO (dispatch failure)
      return;
    }

    const prevCourseId = movedModule.courseId;
    const modulesOfPreviousCourse = tempExistingModules
      .filter((tempModule) => tempModule.courseId === prevCourseId)
      .sort((a, b) => a.order - b.order);

    const previousCourseModulesInOrder = Array.from(modulesOfPreviousCourse);
    previousCourseModulesInOrder.splice(previousIndex, 1);
    const previousCourseModulesInNewOrder = previousCourseModulesInOrder.map(
      (tempModule, index) => {
        return { ...tempModule, order: index };
      }
    );

    // * Optimistic response
    const updatedModule = { ...movedModule, courseId: destinationCourseId, order: 0 };
    dispatch(reorderModulesSuccess([...previousCourseModulesInNewOrder, updatedModule]));

    // * API Call
    try {
      const tempReorderModulesRequestObjects = [
        {
          moduleId: updatedModule.id!,
          order: updatedModule.order,
          courseId: updatedModule.courseId!,
        },
      ];

      const moduleReorderResponse = await reorderModulesAPI(tempReorderModulesRequestObjects);
      dispatch(reorderModulesSuccess(moduleReorderResponse));
    } catch (error: any) {
      dispatch(genericModuleFailure(error?.message || error));
    }
  };
};

const reorderModulesSuccess = (modules: interfaces.IModule[]) => {
  // * Don't need to send back ALL modules, as the updated ones
  // * Will be deduped by the reducer
  return {
    type: ModuleActionType.REORDER_MODULES_SUCCESS,
    payload: { modules },
  };
};

const genericModuleFailure = (errorMessage: string) => {
  return {
    type: ModuleActionType.MODULES_GENERIC_FAILURE,
    payload: { errorMessage },
  };
};

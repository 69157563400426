import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, interfaces } from '../../common';
import { fetchCourses } from '../../courses/actions';

export const CourseContent = () => {
  const { courses }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { courses: allCouress, error, isLoading } = courses;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Courses</h4>
        <h3>{isLoading ? `—` : allCouress.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

import { combineReducers, Reducer } from 'redux';

import { userReducer } from '../user';
import { courseReducer } from '../courses';
import { moduleReducer } from '../modules';
import { audioAssetReducer } from '../audioAssets';
import { dashboardReducer } from '../dashboard';
import { announcementReducer } from '../announcements';
import { modalReducer } from '../common';

export const rootReducer = combineReducers<Reducer>({
  user: userReducer,
  courses: courseReducer,
  modules: moduleReducer,
  audioAssets: audioAssetReducer,
  dashboard: dashboardReducer,
  modal: modalReducer,
  announcements: announcementReducer,
});

import { interfaces } from '../common';
import { AnnouncementActionType } from './actions';

export interface IAnnouncementStore {
  announcements: interfaces.IAnnouncement[];
  lastUpdated: number;
  error: string;
  isLoading: boolean;
}

const initialAnnouncementState: IAnnouncementStore = {
  announcements: [],
  lastUpdated: 0,
  error: '',
  isLoading: false,
};

export const announcementReducer = (
  state = initialAnnouncementState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case AnnouncementActionType.FETCH_ANNOUNCEMENTS:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case AnnouncementActionType.FETCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        lastUpdated: Date.now(),
        isLoading: false,
        announcements: [...payload.announcements],
      };

    case AnnouncementActionType.ADD_SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        isLoading: false,
        error: '',
        announcements: [
          ...state.announcements,
          { ...payload.announcement, lastUpdated: Date.now() },
        ],
      };
    case AnnouncementActionType.UPDATE_SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        isLoading: false,
        error: '',
        announcements: state.announcements.map((tempAnnouncement: interfaces.IAnnouncement) => {
          if (tempAnnouncement.id === payload.announcement.id) {
            return { ...payload.announcement, lastUpdated: Date.now() };
          }

          return tempAnnouncement;
        }),
      };
    case AnnouncementActionType.DELETE_SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        isLoading: false,
        error: '',
        announcements: state.announcements.filter(
          (tempAnnouncement: interfaces.IAnnouncement) => tempAnnouncement.id !== payload.id
        ),
      };
    case AnnouncementActionType.GENERIC_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.errorMessage,
      };
    default:
      return { ...state };
  }
};

import React, { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { PasswordInput, Input, Button, ErrorDisplay } from '../common';
import { loginUser, IUserStore } from '../user';

import amLogo from '../assets/am-horizontal-logo.png';

const LoginWrapper = styled.section`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 12px;
`;

export const Login = () => {
  const userStore: IUserStore = useSelector((state: { user: IUserStore }) => state.user);
  const { isLoading, error } = userStore;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const dispatch = useDispatch();

  const onLogin = async () => {
    if (!isValid()) {
      return;
    }

    dispatch(loginUser({ email, password }));
  };

  const isValid = () => {
    if (email.length <= 0 || password.length <= 0 || userStore.isLoading) {
      return false;
    }

    return true;
  };

  return (
    <LoginWrapper>
      <img src={amLogo} height={150} alt="Active Meditation Logo" />
      <LoginForm
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          onLogin();
        }}
        onKeyPress={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onLogin();
          }
        }}
      >
        <Input
          value={email}
          placeholder="email@gmail.com"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
        />
        <PasswordInput
          type="password"
          value={password}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
        />
        <ErrorDisplay errorMessage={error} />
        <Button disabled={!isValid()} onClick={onLogin} isLoading={isLoading}>
          Login
        </Button>
      </LoginForm>
    </LoginWrapper>
  );
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from '@reach/router';

import { Button, PageSection, PageHeader, ErrorDisplay, MetricsGrid } from '../../common';
import { CourseList } from '../components';
import { CourseContent, ModuleContent, AudioContent } from '../../dashboard/components';
import { MainRoutes, SubRoutes } from '../../constants';
import { fetchCourses } from '../actions';
import { ICourseStore } from '../reducers';

export const AllCourses = (props: RouteComponentProps) => {
  const courseState: ICourseStore = useSelector(
    (state: { courses: ICourseStore }) => state.courses
  );
  const { courses, isLoading, error } = courseState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <PageSection>
      <PageHeader>
        <h1>{MainRoutes.Courses.name}</h1>
        <Link to={`${SubRoutes.RelativeAdd}`}>
          <Button onClick={null}>Add</Button>
        </Link>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <h2>Overview</h2>
          <MetricsGrid>
            <CourseContent />
            <ModuleContent label="Modules" isLibraryContentOnly={false} />
            <AudioContent contentType="course" />
          </MetricsGrid>

          <h2>Course List</h2>
          <CourseList courses={courses} />
        </>
      )}
    </PageSection>
  );
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from '@reach/router';

import { Button, PageSection, PageHeader, ErrorDisplay, interfaces } from '../../common';
import { MainRoutes, SubRoutes } from '../../constants';
import { AnnouncementList } from '../components';
import { fetchAnnouncements } from '../actions';

export const AllAnnouncements = (props: RouteComponentProps) => {
  const { announcements, isLoading, error } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.announcements
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <PageSection>
      <PageHeader>
        <h1>{MainRoutes.Announcements.name}</h1>
        <Link to={`${SubRoutes.RelativeAdd}`}>
          <Button onClick={null}>Add</Button>
        </Link>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      {isLoading ? <div>Loading...</div> : <AnnouncementList announcements={announcements} />}
    </PageSection>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PublicRouter } from './PublicRouter';
import { AuthRouter } from './AuthRouter';

import { getUserFromLocalStorage } from '../utils';
import { setUser } from '../user';

export const Router = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userStore = useSelector((store: any) => store.user);

  const user = userStore.user;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const tempUser = getUserFromLocalStorage();

      if (tempUser) {
        dispatch(setUser(tempUser));
      }
    }

    setIsLoading(false);
  }, [user, dispatch]);

  return isLoading ? <div>Loading...</div> : user ? <AuthRouter /> : <PublicRouter />;
};

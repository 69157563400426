// When files are uploaded, the original file name has some extra items added on to it
// This function is meant to strip them out
// audioAsset_ is appended and a timestamp is affixed at the end (to prevent filename collisions)
export const formatFileName = (filename?: string | null) => {
  if (!filename) {
    return 'No File Name Found';
  }

  const tempFileNameTagRemoved = filename.replace('audioAsset_', '');
  const tempSplitFile = tempFileNameTagRemoved.split('_');
  const endOfFileName = tempSplitFile[tempSplitFile.length - 1];

  // eslint-disable-next-line
  const [timeStamp, fileExtension] = endOfFileName.split('.');

  const finalFormattedFileName = tempSplitFile.reduce(
    (accumulator: string, current: string, index: number) => {
      if (index === tempSplitFile.length - 1) {
        return accumulator + '.' + fileExtension;
      } else {
        return accumulator + current;
      }
    },
    ''
  );

  return finalFormattedFileName;
};

import React, { ChangeEvent, useRef } from 'react';

import {
  interfaces,
  Input,
  InputGroup,
  InputLabel,
  TextAreaInput,
  PageForm,
  Checkbox,
  Row,
  DangerButton,
} from '../../common';
import { CoursePreview } from './CoursePreview';

interface IProps {
  isEditMode: boolean;
  course: interfaces.ICourse | null;
  onUpdateForm: (updatedField: { [key: string]: any }) => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const CourseForm = ({ course, onUpdateForm, onSubmit, onDelete, isEditMode }: IProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const onFileChange = () => {
    if (fileInput.current?.files) {
      onUpdateForm({ image: fileInput.current.files[0], imageUrl: '' });
    }
  };

  return (
    course && (
      <PageForm
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <CoursePreview
          course={course}
          imageFile={fileInput.current?.files && fileInput?.current?.files[0]}
        />
        <Row style={{ marginBottom: 6, height: 46 }}>
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onUpdateForm({ isPublished: !course.isPublished });
            }}
            label="Published"
            value={course.isPublished || false}
            disabled={!isEditMode}
          />
          {isEditMode && course.id && !course.isPublished && (
            <DangerButton
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                if (onDelete) {
                  onDelete();
                }
              }}
              style={{ marginLeft: 12 }}
            >
              Delete?
            </DangerButton>
          )}
        </Row>
        <Input
          placeholder="Course Title"
          value={course.title}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onUpdateForm({ title: event.target.value })
          }
          disabled={!isEditMode}
        />
        <Input
          placeholder="Average Track Length (10-15m)"
          value={course.averageTrackLength || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onUpdateForm({ averageTrackLength: event.target.value })
          }
          disabled={!isEditMode}
        />
        <TextAreaInput
          placeholder="Description"
          value={course.desc || ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onUpdateForm({ desc: event.target.value })
          }
          disabled={!isEditMode}
        />
        <Row style={{ marginTop: 8, marginBottom: 8 }}>
          <InputGroup>
            <InputLabel>Course Level</InputLabel>
            <Input
              type="number"
              placeholder="Level of Course"
              value={course.level || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ level: event.target.value })
              }
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Course Order</InputLabel>
            <Input
              type="number"
              placeholder="Order of Course"
              value={course.order || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ order: event.target.value })
              }
            />
          </InputGroup>
        </Row>
        <InputGroup style={{ marginBottom: 12 }}>
          <InputLabel>Image URL (Optional)</InputLabel>
          <Input
            placeholder="Optional URL"
            value={course.imageUrl}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onUpdateForm({ imageUrl: event.target.value })
            }
            disabled={!isEditMode}
          />
        </InputGroup>
        {isEditMode ? (
          <InputGroup>
            <InputLabel>Image File (Optional)</InputLabel>
            <Input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept="image/png,image/jpeg"
            />
          </InputGroup>
        ) : (
          <InputGroup>
            <InputLabel>Image File</InputLabel>
            <a
              style={{ marginBottom: 12, marginTop: 2 }}
              href={course.imageUrl ? course.imageUrl : ''}
            >
              {course.imageUrl}
            </a>
          </InputGroup>
        )}
      </PageForm>
    )
  );
};

import { interfaces } from '../common';
import { AudioAssetActionType } from './constants';

export interface IAudioAssetStore {
  isLoading: boolean;
  error: string;
  lastUpdated: number;
  audioAssets: interfaces.IAudioAsset[];
  audioAssetAnalytics: {
    [key: string]: {
      timesCompleted: number;
      timesAbandoned: number;
      completionRatePercentage: number;
      dailyPlays: number;
      weeklyPlays: number;
      monthlyPlays: number;
      lastUpdated: number;
    };
  };
}

const initialAudioAssetState = {
  isLoading: false,
  error: '',
  lastUpdated: 0,
  audioAssets: [],
  audioAssetAnalytics: {},
};

export const audioAssetReducer = (
  state = initialAudioAssetState,
  { type, payload }: { type: AudioAssetActionType; payload: any }
) => {
  switch (type) {
    case AudioAssetActionType.FETCH_AUDIO_ASSETS:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case AudioAssetActionType.FETCH_AUDIO_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        lastUpdated: Date.now(),
        audioAssets: [...payload.audioAssets],
      };
    case AudioAssetActionType.ADD_SINGLE_AUDIO_ASSET:
      return {
        ...state,
        isLoading: false,
        error: '',
        audioAssets: [...state.audioAssets, { ...payload.audioAsset, lastUpdated: Date.now() }],
      };
    case AudioAssetActionType.UPDATE_SINGLE_AUDIO_ASSET:
      return {
        ...state,
        isLoading: false,
        error: '',
        audioAssets: state.audioAssets.map((tempAudioAsset: interfaces.IAudioAsset) => {
          if (tempAudioAsset.id === payload.audioAsset.id) {
            return { ...payload.audioAsset, lastUpdated: Date.now() };
          }

          return tempAudioAsset;
        }),
      };
    case AudioAssetActionType.DELETE_SINGLE_AUDIO_ASSET:
      return {
        ...state,
        isLoading: false,
        error: '',
        audioAssets: state.audioAssets.filter(
          (tempAudioAsset: interfaces.IAudioAsset) => tempAudioAsset.id !== payload.id
        ),
      };
    case AudioAssetActionType.AUDIO_ASSET_GENERIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.errorMessage,
      };
    case AudioAssetActionType.FETCH_AUDIO_ASSET_ANALYTICS_BY_ID:
      return {
        ...state,
        audioAssetAnalytics: {
          ...state.audioAssetAnalytics,
          // ? Add a new entry for the audio asset analytics
          // ? And set status? Do we need to set status?
        },
      };
    case AudioAssetActionType.FETCH_AUDIO_ASSET_ANALYTICS_BY_ID_SUCCESS:
      return {
        ...state,
        audioAssetAnalytics: {
          ...state.audioAssetAnalytics,
          [payload.id]: {
            ...payload,
            lastUpdated: Date.now(),
          },
        },
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import { AnnouncementForm } from '../components';
import { PageSection, PageHeader, ErrorDisplay, Button, interfaces } from '../../common';
import { MainRoutes } from '../../constants';
import { createAnnouncement } from '../actions';

export const AddAnnouncement = (props: RouteComponentProps) => {
  const { isLoading, error } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.announcements
  );
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState<interfaces.IAnnouncement>({
    title: '',
    label: '',
    desc: '',
    order: 0,
    linkedContentItemId: '',
    type: interfaces.AnnouncementType.COURSE,
    isPublished: true,
    image: undefined,
  });

  const onUpdateAnnouncement = (updatedField: { [key: string]: any }) => {
    setAnnouncement({ ...announcement, ...updatedField });
  };

  const onCreateAnnouncement = async () => {
    try {
      await dispatch(createAnnouncement(announcement));
      navigate(MainRoutes.Announcements.path);
    } catch (error: any) {
      console.log('error creating announcement — ', error);
    }
  };

  const isValid = () => {
    if (!announcement.title || !announcement.desc || !announcement.linkedContentItemId) {
      return false;
    }

    return true;
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>Add Announcement</h1>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <AnnouncementForm
        announcement={announcement}
        isEditMode={true}
        onSubmit={onCreateAnnouncement}
        onUpdateForm={onUpdateAnnouncement}
      />
      <Button onClick={onCreateAnnouncement} disabled={!isValid()} isLoading={isLoading}>
        Save
      </Button>
    </PageSection>
  );
};

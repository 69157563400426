import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, TextButton } from './buttons';
import { Row } from './layout';
import { closeModal } from './actions';
import { IStyledComponent } from './interfaces';
import { theme } from './styles/theme';

export enum ModalType {
  Danger = 'DANGER',
  Warning = 'WARNING',
  Info = 'INFO',
  Default = 'DEFAULT',
}

const modalHeaderColors = {
  [ModalType.Danger]: theme.colors.error,
  [ModalType.Warning]: theme.colors.warning,
  [ModalType.Info]: theme.colors.primary,
  [ModalType.Default]: theme.colors.white,
};

interface IProps {
  title: string;
  message: string;
  type?: ModalType;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 500px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  box-shadow: 3px 1px 6px rgba(33, 33, 33, 0.3), 0px 0px 12px rgba(33, 33, 33, 0.1);
`;

interface ITitleProps {
  readonly type: ModalType;
}

const TitleWrapper = styled.div<ITitleProps>`
  padding: 12px;
  background-color: ${props => modalHeaderColors[props.type]};

  border-top-right-radius: ${({ theme }: IStyledComponent) => theme.defaultBorderRadius};
  border-top-left-radius: ${({ theme }: IStyledComponent) => theme.defaultBorderRadius};

  h3 {
    color: ${({ theme }: IStyledComponent) => theme.colors.white};
  }
`;

const MessageWrapper = styled.div`
  padding: 12px;
  margin-top: 12px;
`;

export const Modal = ({
  title,
  message,
  onConfirm,
  onCancel,
  type = ModalType.Default,
}: IProps) => {
  const dispatch = useDispatch();

  return (
    <ModalWrapper>
      <TitleWrapper type={type}>
        <h3>{title}</h3>
      </TitleWrapper>
      <MessageWrapper>
        <p>{message}</p>
      </MessageWrapper>
      <Row style={{ justifyContent: 'flex-end', margin: 12 }}>
        <TextButton
          onClick={async (event: MouseEvent) => {
            event.stopPropagation();
            if (onCancel) {
              await onCancel();
            }
            dispatch(closeModal());
          }}
        >
          <p>Cancel</p>
        </TextButton>
        <div style={{ width: 16 }} />
        <Button
          onClick={async (event: MouseEvent) => {
            event.stopPropagation();
            if (onConfirm) {
              await onConfirm();
            }
            dispatch(closeModal());
          }}
        >
          Confirm
        </Button>
      </Row>
    </ModalWrapper>
  );
};

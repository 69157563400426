import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';

import { SubRoutes, MainRoutes } from '../../constants';
import { interfaces } from '../../common';

const AudioAssetLink = styled(Link)``;

interface IProps {
  audioAsset: interfaces.IAudioAsset;
}

export const AudioAssetListItem = ({ audioAsset }: IProps) => {
  return (
    <AudioAssetLink
      key={audioAsset.id}
      to={`${MainRoutes.AudioAssets.path}/${SubRoutes.AudioAsset}/${audioAsset.id}`}
    >
      {audioAsset.title}
    </AudioAssetLink>
  );
};

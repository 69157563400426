import { interfaces } from '../common';
import { DashboardActionType } from './constants';
import { Status } from '../constants';

export interface IDashboardStore {
  dailyActiveUsers: {
    dailyActiveUsers: interfaces.IUser[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
  allUsers: {
    allUsers: interfaces.IUser[];
    status: Status;
    error: string;
    lastUpdated: number;
  };
  weeklyActiveUsers: {
    weeklyUsers: interfaces.IUser[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
  monthlyActiveUsers: {
    monthlyUsers: interfaces.IUser[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
  trackData: {
    allTimePlays: number;
    dailyPlays: number;
    weeklyPlays: number;
    monthlyPlays: number;
    error: string;
    status: Status;
    lastUpdated: number;
  };
  trackWeeklyTrending: {
    data: interfaces.ITrackTrendingData[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
  trackMonthlyTrending: {
    data: interfaces.ITrackTrendingData[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
  trackAllTimeTrending: {
    data: interfaces.ITrackTrendingData[];
    status: Status;
    lastUpdated: number;
    error: string;
  };
}

const intialDashboardState: IDashboardStore = {
  dailyActiveUsers: {
    dailyActiveUsers: [],
    status: Status.Success,
    error: '',
    lastUpdated: 0,
  },
  allUsers: {
    allUsers: [],
    status: Status.Success,
    error: '',
    lastUpdated: 0,
  },
  weeklyActiveUsers: {
    weeklyUsers: [],
    status: Status.Success,
    error: '',
    lastUpdated: 0,
  },
  monthlyActiveUsers: {
    monthlyUsers: [],
    status: Status.Success,
    error: '',
    lastUpdated: 0,
  },
  trackData: {
    allTimePlays: 0,
    dailyPlays: 0,
    weeklyPlays: 0,
    monthlyPlays: 0,
    lastUpdated: 0,
    error: '',
    status: Status.Success,
  },
  trackWeeklyTrending: {
    data: [],
    status: Status.Success,
    lastUpdated: 0,
    error: '',
  },
  trackMonthlyTrending: {
    data: [],
    status: Status.Success,
    lastUpdated: 0,
    error: '',
  },
  trackAllTimeTrending: {
    data: [],
    status: Status.Success,
    lastUpdated: 0,
    error: '',
  },
};

export const dashboardReducer = (
  state = intialDashboardState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case DashboardActionType.FETCH_DAILY_USERS:
      return {
        ...state,
        dailyActiveUsers: {
          ...state.dailyActiveUsers,
          isLoading: true,
          error: '',
        },
      };
    case DashboardActionType.FETCH_DAILY_USERS_SUCCESS:
      return {
        ...state,
        dailyActiveUsers: {
          ...state.dailyActiveUsers,
          status: Status.Success,
          dailyActiveUsers: [...payload.users],
          error: '',
          lastUpdated: Date.now(),
        },
      };
    case DashboardActionType.FETCH_DAILY_USERS_FAILURE:
      return {
        ...state,
        dailyActiveUsers: {
          ...state.dailyActiveUsers,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_ALL_USERS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          status: Status.Success,
          allUsers: [...payload.users],
          error: '',
          lastUpdated: Date.now(),
        },
      };
    case DashboardActionType.FETCH_ALL_USERS_FAILURE:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_WEEKLY_USERS:
      return {
        ...state,
        weeklyActiveUsers: {
          ...state.weeklyActiveUsers,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_WEEKLY_USERS_SUCCESS:
      return {
        ...state,
        weeklyActiveUsers: {
          ...state.weeklyActiveUsers,
          status: Status.Success,
          weeklyUsers: [...payload.users],
          error: '',
          lastUpdated: Date.now(),
        },
      };
    case DashboardActionType.FETCH_WEEKLY_USERS_FAILURE:
      return {
        ...state,
        weeklyActiveUsers: {
          ...state.weeklyActiveUsers,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_MONTHLY_USERS:
      return {
        ...state,
        monthlyActiveUsers: {
          ...state.monthlyActiveUsers,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_MONTHLY_USERS_SUCCESS:
      return {
        ...state,
        monthlyActiveUsers: {
          ...state.monthlyActiveUsers,
          status: Status.Success,
          monthlyUsers: [...payload.users],
          error: '',
          lastUpdated: Date.now(),
        },
      };
    case DashboardActionType.FETCH_MONTHLY_USERS_FAILURE:
      return {
        ...state,
        monthlyActiveUsers: {
          ...state.monthlyActiveUsers,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_TRACK_DATA:
      return {
        ...state,
        trackData: {
          ...state.trackData,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_TRACK_DATA_SUCCESS:
      return {
        ...state,
        trackData: {
          ...state.trackData,
          status: Status.Success,
          allTimePlays: payload.allTimePlays,
          dailyPlays: payload.dailyPlays,
          weeklyPlays: payload.weeklyPlays,
          monthlyPlays: payload.monthlyPlays,
          error: '',
          lastUpdated: Date.now(),
        },
      };
    case DashboardActionType.FETCH_TRACK_DATA_FAILURE:
      return {
        ...state,
        trackData: {
          ...state.trackData,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING:
      return {
        ...state,
        trackWeeklyTrending: {
          ...state.trackWeeklyTrending,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING_FAILURE:
      return {
        ...state,
        trackWeeklyTrending: {
          ...state.trackWeeklyTrending,
          status: Status.Error,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING_SUCCESS:
      return {
        ...state,
        trackWeeklyTrending: {
          ...state.trackWeeklyTrending,
          status: Status.Success,
          data: [...payload.data],
          error: '',
          lastUpdated: Date.now(),
        },
      };
      case DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING:
      return {
        ...state,
        trackMonthlyTrending: {
          ...state.trackMonthlyTrending,
          status: Status.Loading,
          error: '',
        },
      };
    case DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING_FAILURE:
      return {
        ...state,
        trackMonthlyTrending: {
          ...state.trackMonthlyTrending,
          status: Status.Error,
          error: payload.errorMessage,
        },
      };
    case DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING_SUCCESS:
      return {
        ...state,
        trackMonthlyTrending: {
          ...state.trackMonthlyTrending,
          status: Status.Success,
          data: [...payload.data],
          error: '',
          lastUpdated: Date.now(),
        },
      };
      case DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING:
        return {
          ...state,
          trackAllTimeTrending: {
            ...state.trackAllTimeTrending,
            status: Status.Loading,
            error: '',
          },
        };
      case DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING_FAILURE:
        return {
          ...state,
          trackAllTimeTrending: {
            ...state.trackAllTimeTrending,
            status: Status.Error,
            error: payload.errorMessage,
          },
        };
      case DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING_SUCCESS:
        return {
          ...state,
          trackAllTimeTrending: {
            ...state.trackAllTimeTrending,
            status: Status.Success,
            data: [...payload.data],
            error: '',
            lastUpdated: Date.now(),
          },
        };
    default:
      return state;
  }
};

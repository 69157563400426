export enum ModuleActionType {
  FETCH_MODULES = 'FETCH_MODULES',
  FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS',
  FETCH_MODULES_BY_COURSE_ID = 'FETCH_MODULES_BY_COURSE_ID',
  FETCH_MODULES_BY_COURSE_ID_SUCCESS = 'FETCH_MODULES_BY_COURSE_ID_SUCCESS',
  ADD_SINGLE_MODULE = 'ADD_SINGLE_MODULE',
  UPDATE_SINGLE_MODULE = 'UPDATE_SINGLE_MODULE',
  MODULES_GENERIC_FAILURE = 'MODULES_GENERIC_FAILURE',
  REORDER_MODULES = 'REORDER_MODULES',
  REORDER_MODULES_SUCCESS = 'REORDER_MODULES_SUCCESS',
  DELETE_SINGLE_MODULE = 'DELETE_SINGLE_MODULE',
}

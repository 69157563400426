import React, { useRef, ChangeEvent } from 'react';

import {
  interfaces,
  PageForm,
  Row,
  Checkbox,
  DangerButton,
  Input,
  TextAreaInput,
  InputGroup,
  InputLabel,
} from '../../common';
import { AnnouncementTypeSelect } from './AnnouncementTypeSelect';
import { AnnouncementPreview } from './AnnouncementPreview';

interface IProps {
  isEditMode: boolean;
  announcement: interfaces.IAnnouncement | null;
  onUpdateForm: (updatedField: { [key: string]: any }) => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const AnnouncementForm = ({
  isEditMode,
  announcement,
  onUpdateForm,
  onSubmit,
  onDelete,
}: IProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const onFileChange = () => {
    if (fileInput.current?.files) {
      onUpdateForm({ image: fileInput.current.files[0], imageUrl: '' });
    }
  };

  return (
    announcement && (
      <PageForm>
        <AnnouncementPreview
          announcement={announcement}
          imageFile={fileInput.current?.files && fileInput?.current?.files[0]}
        />
        <Row style={{ marginBottom: 6, height: 46 }}>
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onUpdateForm({ isPublished: !announcement.isPublished });
            }}
            label="Published"
            value={announcement.isPublished || false}
            disabled={!isEditMode}
          />
          {isEditMode && announcement.id && !announcement.isPublished && (
            <DangerButton
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                if (onDelete) {
                  onDelete();
                }
              }}
              style={{ marginLeft: 12 }}
            >
              Delete?
            </DangerButton>
          )}
        </Row>
        <Input
          placeholder="Announcement Title"
          value={announcement.title}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onUpdateForm({ title: event.target.value })
          }
          disabled={!isEditMode}
        />
        <TextAreaInput
          placeholder="Announcement Description"
          value={announcement.desc || ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onUpdateForm({ desc: event.target.value })
          }
          disabled={!isEditMode}
        />
        <InputGroup>
          <InputLabel>Linked Item</InputLabel>
          <AnnouncementTypeSelect
            disabled={!isEditMode}
            announcement={announcement}
            linkedContentItemId={announcement.linkedContentItemId}
            onChange={(updatedFields: any) => onUpdateForm(updatedFields)}
          />
        </InputGroup>
        <Row style={{ marginBottom: 6 }}>
          <InputGroup>
            <InputLabel>Label</InputLabel>
            <Input
              placeholder="Updated"
              value={announcement.label}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ label: event.target.value })
              }
              disabled={!isEditMode}
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Content Duration</InputLabel>
            <Input
              placeholder="4-20m exercises"
              value={announcement.contentDuration}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ contentDuration: event.target.value })
              }
              disabled={!isEditMode}
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Image URL (Optional)</InputLabel>
            <Input
              placeholder="Optional URL"
              value={announcement.imageUrl}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ imageUrl: event.target.value })
              }
              disabled={!isEditMode}
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Order</InputLabel>
            <Input
              type="number"
              placeholder="Order in Module"
              value={announcement.order || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ order: event.target.value })
              }
            />
          </InputGroup>
        </Row>
        {isEditMode ? (
          <InputGroup>
            <InputLabel>Image File (Optional)</InputLabel>
            <Input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept="image/png,image/jpeg"
            />
          </InputGroup>
        ) : (
          <InputGroup>
            <InputLabel>Image File</InputLabel>
            <a
              style={{ marginBottom: 12, marginTop: 2 }}
              href={announcement.imageUrl ? announcement.imageUrl : ''}
            >
              {announcement.imageUrl}
            </a>
          </InputGroup>
        )}
      </PageForm>
    )
  );
};

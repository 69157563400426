import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import {
  PageSection,
  PageHeader,
  Button,
  interfaces,
  ErrorDisplay,
  Modal,
  ModalType,
  openModal,
} from '../../common';
import { MainRoutes } from '../../constants';
import { AudioAssetType } from '../constants';
import {
  fetchSingleAudioAsset,
  editAudioAsset,
  deleteAudioAsset as deleteAudioAssetAction,
} from '../actions';
import { AudioAssetForm, AudioAssetAnalytics } from '../components';

interface IProps extends RouteComponentProps {
  audioAssetId?: interfaces.ID;
}

export const EditAudioAsset = ({ audioAssetId }: IProps) => {
  const { isLoading, error, audioAssets } = useSelector(
    (state: interfaces.IGlobalStoreState) => state.audioAssets
  );
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentAudioAsset, setCurretAudioAsset] = useState<interfaces.IAudioAsset | undefined>(
    undefined
  );
  const [currentEditableAudioAsset, setCurrentEditableAudioAsset] =
    useState<interfaces.IAudioAsset | null>(null);

  useEffect(() => {
    const audioAsset = audioAssets.find(
      (tempAudioAsset: interfaces.IAudioAsset) => tempAudioAsset.id === audioAssetId
    );

    if (audioAssetId) {
      dispatch(fetchSingleAudioAsset(audioAssetId));
    }

    if (audioAsset) {
      setCurretAudioAsset(audioAsset);
      setCurrentEditableAudioAsset(audioAsset);
    }
  }, [audioAssetId, dispatch, audioAssets]);

  const onUpdateAudioAsset = (updatedField: { [key: string]: any }) => {
    setCurrentEditableAudioAsset(
      currentEditableAudioAsset
        ? { ...currentEditableAudioAsset, ...updatedField }
        : {
            title: '',
            desc: null,
            url: null,
            level: 0,
            order: 0,
            type: AudioAssetType.EXERCISE,
            isPublished: true,
            ...updatedField,
          }
    );
  };

  const onSaveEdittedAudioAsset = async () => {
    if (!currentEditableAudioAsset) {
      return;
    }

    try {
      await dispatch(editAudioAsset(currentEditableAudioAsset));
      navigate(MainRoutes.AudioAssets.path);
    } catch (error: any) {
      console.log('error — ', error);
    }
  };

  const onDeleteAudioAsset = () => {
    dispatch(
      openModal(
        <Modal
          title="Delete Audio Asset?"
          message="Are you sure? This action can not be undone."
          onConfirm={deleteAudioAsset}
          type={ModalType.Danger}
        />
      )
    );
  };

  const deleteAudioAsset = async () => {
    navigate(MainRoutes.AudioAssets.path);
    await dispatch(deleteAudioAssetAction(currentAudioAsset && currentAudioAsset.id));
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>{currentAudioAsset?.title || 'Loading...'}</h1>
        <Button onClick={() => setIsEditMode(!isEditMode)}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      {audioAssetId && <AudioAssetAnalytics id={audioAssetId} />}
      <AudioAssetForm
        onSubmit={onSaveEdittedAudioAsset}
        onUpdateForm={onUpdateAudioAsset}
        audioAsset={isEditMode ? currentEditableAudioAsset : currentAudioAsset || null}
        isEditMode={isEditMode}
        onDelete={onDeleteAudioAsset}
      />
      <Button onClick={onSaveEdittedAudioAsset} isLoading={isLoading} disabled={!isEditMode}>
        Save
      </Button>
    </PageSection>
  );
};

import React from 'react';
import styled from 'styled-components';

import { SidebarContent } from './SidebarContent';
import { Sidebar } from './Sidebar';

const StyledLayout: React.FC = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  background-color: ${({ theme }) => theme.colors.white};
  grid-template-columns: ${({ theme }) => `${theme.sidebarWidthLg} 1fr`};
`;

export const SidebarLayout: React.FC = ({ children }) => {
  return (
    <StyledLayout>
      <Sidebar />
      <SidebarContent>{children}</SidebarContent>
    </StyledLayout>
  );
};

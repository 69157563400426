import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { SidebarLayout } from '../common';
import { SubRoutes } from '../constants';

import { AllModules } from './containers/AllModules';
import { AddModule } from './containers/AddModule';
import { EditModule } from './containers/EditModule';

export const Modules = (props: RouteComponentProps) => {
  return (
    <SidebarLayout>
      <Router>
        <AllModules path="/" />
        <AddModule path={`${SubRoutes.RelativeAdd}`} />
        <AddModule path={`${SubRoutes.RelativeAdd}/:courseId`} />
        <EditModule path={`${SubRoutes.Module}/:moduleId`} />
      </Router>
    </SidebarLayout>
  );
};

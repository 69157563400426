import React, { useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { interfaces, Select, Option } from '../../common';
import { fetchCourses } from '../actions';

interface IProps {
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

export const CourseSelect = ({ value = '', onChange, disabled }: IProps) => {
  const courses = useSelector(({ courses }: interfaces.IGlobalStoreState) => courses.courses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    courses && (
      <Select value={value} onChange={event => onChange(event)} disabled={disabled}>
        <Option key="none" value={''}>
          None
        </Option>
        {courses.map((tempCourse: interfaces.ICourse) => (
          <Option key={tempCourse.id} value={tempCourse.id}>
            {tempCourse.title}
          </Option>
        ))}
      </Select>
    )
  );
};

import React, { useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';

import { interfaces, Button, PageHeader, PageSection, ErrorDisplay } from '../../common';
import { MainRoutes } from '../../constants';
import { CourseForm } from '../components';
import { createCourse } from '../actions';
import { ICourseStore } from '../reducers';

interface IProps extends RouteComponentProps {}

export const AddCourse = (props: IProps) => {
  const { isLoading, error } = useSelector((state: { courses: ICourseStore }) => state.courses);
  const dispatch = useDispatch();
  const [course, setCourse] = useState<interfaces.ICourse>({
    title: '',
    level: 0,
    order: 0,
    desc: '',
    isPublished: true,
    imageUrl: '',
    image: undefined,
  });

  const onUpdateCourse = (updatedField: { [key: string]: any }) => {
    setCourse({ ...course, ...updatedField });
  };

  const onCreateCourse = async () => {
    try {
      await dispatch(createCourse(course));
      navigate(MainRoutes.Courses.path);
    } catch (error: any) {
      console.log('error creating course — ', error);
    }
  };

  const isValid = () => {
    if (!course.title) {
      return false;
    }

    return true;
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>Add Course</h1>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <CourseForm
        course={course}
        onUpdateForm={onUpdateCourse}
        onSubmit={onCreateCourse}
        isEditMode={true}
      />
      <Button
        onClick={onCreateCourse}
        style={{ alignSelf: 'flex-end' }}
        disabled={!isValid()}
        isLoading={isLoading}
      >
        Create
      </Button>
    </PageSection>
  );
};

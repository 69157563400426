import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, interfaces, DataField } from '../../common';
import { fetchAudioAssets } from '../../audioAssets/actions';

interface IAudioContentProps {
  // * True will give you back library ONLY
  // * False will give you back course ONLY
  // * Undefined will give you back both
  isLibraryContentOnly?: boolean;
  contentType?: 'library' | 'course' | 'all';
}

export const AudioContent = ({ isLibraryContentOnly, contentType = 'all' }: IAudioContentProps) => {
  const { audioAssets }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { audioAssets: allAudioAssets, error, isLoading } = audioAssets;
  const isLibraryContent = contentType === 'library';

  const selectedContentAudioAssets = allAudioAssets.filter(
    (tempAudioAsset) => tempAudioAsset.isLibrary === isLibraryContent
  );

  const trackCount =
    contentType === 'all' ? allAudioAssets.length : selectedContentAudioAssets.length;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAudioAssets());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Tracks</h4>
        <h3>{isLoading ? `—` : trackCount}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

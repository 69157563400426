import { Dispatch } from 'redux';
import { DashboardActionType } from './constants';

import { interfaces } from '../common';
import { Time, isOlderThan } from '../utils';
import {
  getDailyActiveUsers,
  getAllUsers,
  getWeeklyUsers,
  getMonthlyUsers,
  getTrackData,
  getTrendingTracks,
} from '../api';
import { Status } from '../constants';

export const fetchDailyActiveUsers = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().dashboard.dailyActiveUsers.lastUpdated;
    if (!isOlderThan(Time.FiveMinutes, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchDailyUsersInit());
    try {
      const tempUsers = await getDailyActiveUsers();
      dispatch(fetchDailyUsersSuccess(tempUsers));
    } catch (error: any) {
      dispatch(fetchDailyUsersFailure(error.message));
    }
  };
};

const fetchDailyUsersInit = () => {
  return {
    type: DashboardActionType.FETCH_DAILY_USERS,
    payload: null,
  };
};

const fetchDailyUsersSuccess = (users: interfaces.IUser[]) => {
  return {
    type: DashboardActionType.FETCH_DAILY_USERS_SUCCESS,
    payload: { users },
  };
};

const fetchDailyUsersFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_DAILY_USERS_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchAllUsers = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().dashboard.allUsers.lastUpdated;
    if (!isOlderThan(Time.FiveMinutes, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchAllUsersInit());
    try {
      const tempUsers = await getAllUsers();
      dispatch(fetchAllUsersSuccess(tempUsers));
    } catch (error: any) {
      dispatch(fetchAllUsersFailure(error.message));
    }
  };
};

const fetchAllUsersInit = () => {
  return {
    type: DashboardActionType.FETCH_ALL_USERS,
    payload: null,
  };
};

const fetchAllUsersSuccess = (users: interfaces.IUser[]) => {
  return {
    type: DashboardActionType.FETCH_ALL_USERS_SUCCESS,
    payload: { users },
  };
};

const fetchAllUsersFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_ALL_USERS_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchWeeklyUsers = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().dashboard.weeklyActiveUsers.lastUpdated;
    if (!isOlderThan(Time.FiveMinutes, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchWeeklyUsersInit());
    try {
      const tempUsers = await getWeeklyUsers();
      dispatch(fetchWeeklyUsersSuccess(tempUsers));
    } catch (error: any) {
      dispatch(fetchWeeklyUsersFailure(error.message));
    }
  };
};

const fetchWeeklyUsersInit = () => {
  return {
    type: DashboardActionType.FETCH_WEEKLY_USERS,
    payload: null,
  };
};

const fetchWeeklyUsersSuccess = (users: interfaces.IUser[]) => {
  return {
    type: DashboardActionType.FETCH_WEEKLY_USERS_SUCCESS,
    payload: { users },
  };
};

const fetchWeeklyUsersFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_WEEKLY_USERS_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchMonthlyUsers = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().dashboard.monthlyActiveUsers.lastUpdated;
    if (!isOlderThan(Time.FiveMinutes, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchMonthlyUsersInit());
    try {
      const tempUsers = await getMonthlyUsers();
      dispatch(fetchMonthlyUsersSuccess(tempUsers));
    } catch (error: any) {
      dispatch(fetchMonthlyUsersFailure(error.message));
    }
  };
};

const fetchMonthlyUsersInit = () => {
  return {
    type: DashboardActionType.FETCH_MONTHLY_USERS,
    payload: null,
  };
};

const fetchMonthlyUsersSuccess = (users: interfaces.IUser[]) => {
  return {
    type: DashboardActionType.FETCH_MONTHLY_USERS_SUCCESS,
    payload: { users },
  };
};

const fetchMonthlyUsersFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_MONTHLY_USERS_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchTrackData = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempCurrentTrackData = getState().dashboard.trackData;
    if (
      !isOlderThan(Time.FiveMinutes, tempCurrentTrackData.lastUpdated) ||
      tempCurrentTrackData.status === Status.Loading
    ) {
      Promise.resolve();
      return;
    }

    dispatch(fetchTrackDataInit());
    try {
      const tempTrackData = await getTrackData();
      dispatch(fetchTrackDataSuccess(tempTrackData));
    } catch (error: any) {
      dispatch(fetchTrackDataFailure(error.message));
    }
  };
};

const fetchTrackDataInit = () => {
  return {
    type: DashboardActionType.FETCH_TRACK_DATA,
    payload: null,
  };
};

const fetchTrackDataSuccess = (trackData: any) => {
  return {
    type: DashboardActionType.FETCH_TRACK_DATA_SUCCESS,
    payload: { ...trackData },
  };
};

const fetchTrackDataFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_TRACK_DATA_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchTrendingWeeklyTrackData = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempCurrentWeeklyTrending = getState().dashboard.trackWeeklyTrending;
    if (
      !isOlderThan(Time.FiveMinutes, tempCurrentWeeklyTrending.lastUpdated) ||
      tempCurrentWeeklyTrending.status === Status.Loading
    ) {
      Promise.resolve();
      return;
    }

    dispatch(fetchTrendingWeeklyInit());
    try {
      const tempTrackData = await getTrendingTracks(7);
      dispatch(fetchTrendingWeeklySuccess(tempTrackData));
    } catch (error: any) {
      dispatch(fetchTrendingWeeklyFailure(error.message));
    }
  };
};

const fetchTrendingWeeklyInit = () => {
  return {
    type: DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING,
    payload: null,
  };
};

const fetchTrendingWeeklySuccess = (data: any) => {
  return {
    type: DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING_SUCCESS,
    payload: { data },
  };
};

const fetchTrendingWeeklyFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_TRACK_WEEKLY_TRENDING_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchTrendingMonthlyTrackData = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempCurrentMonthlyTrending = getState().dashboard.trackMonthlyTrending;
    if (
      !isOlderThan(Time.FiveMinutes, tempCurrentMonthlyTrending.lastUpdated) ||
      tempCurrentMonthlyTrending.status === Status.Loading
    ) {
      Promise.resolve();
      return;
    }

    dispatch(fetchTrendingMonthlyInit());
    try {
      const tempTrackData = await getTrendingTracks(30);
      dispatch(fetchTrendingMonthlySuccess(tempTrackData));
    } catch (error: any) {
      dispatch(fetchTrendingMonthlyFailure(error.message));
    }
  };
};

const fetchTrendingMonthlyInit = () => {
  return {
    type: DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING,
    payload: null,
  };
};

const fetchTrendingMonthlySuccess = (data: any) => {
  return {
    type: DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING_SUCCESS,
    payload: { data },
  };
};

const fetchTrendingMonthlyFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_TRACK_MONTHLY_TRENDING_FAILURE,
    payload: { errorMessage },
  };
};

export const fetchTrendingAllTimeTrackData = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempCurrentAllTimeTrending = getState().dashboard.trackAllTimeTrending;
    if (
      !isOlderThan(Time.FiveMinutes, tempCurrentAllTimeTrending.lastUpdated) ||
      tempCurrentAllTimeTrending.status === Status.Loading
    ) {
      Promise.resolve();
      return;
    }

    dispatch(fetchTrendingAllTimeInit());
    try {
      const tempTrackData = await getTrendingTracks();
      dispatch(fetchTrendingAllTimeSuccess(tempTrackData));
    } catch (error: any) {
      dispatch(fetchTrendingAllTimeFailure(error.message));
    }
  };
};

const fetchTrendingAllTimeInit = () => {
  return {
    type: DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING,
    payload: null,
  };
};

const fetchTrendingAllTimeSuccess = (data: any) => {
  return {
    type: DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING_SUCCESS,
    payload: { data },
  };
};

const fetchTrendingAllTimeFailure = (errorMessage: string) => {
  return {
    type: DashboardActionType.FETCH_TRACK_ALL_TIME_TRENDING_FAILURE,
    payload: { errorMessage },
  };
};

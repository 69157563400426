import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, interfaces } from '../../common';
import { fetchMonthlyUsers } from '../actions';
import { Status } from '../../constants';

export const MonthlyUsers = () => {
  const { dashboard }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { monthlyUsers, status, error } = dashboard.monthlyActiveUsers;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMonthlyUsers());
  }, [dispatch]);

  return (
    <div>
      <DataField>
        <h4>Monthly Active Users</h4>
        <h3>{status === Status.Loading ? `—` : monthlyUsers.length}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </div>
  );
};

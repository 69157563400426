import { api } from './api';
import { interfaces } from '../common';

export const getModules = async (): Promise<interfaces.IModule[]> => {
  try {
    const tempModulesResponse = await api.get(`/api/v1/modules`);

    return tempModulesResponse.data.modules;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getModuleById = async (id: interfaces.ID): Promise<interfaces.IModule> => {
  try {
    const tempModulesResponse = await api.get(`/api/v1/module/${id}?includeUnpublished=true`);

    return tempModulesResponse.data;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getModulesByCourseId = async (id: interfaces.ID): Promise<interfaces.IModule[]> => {
  try {
    const tempModulesResponse = await api.get(`/api/v1/courses/modules/?courseId=${id}`);

    return tempModulesResponse.data.modules;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const createModule = async (module: interfaces.IModule): Promise<interfaces.IModule> => {
  try {
    const tempCreateCourseResponse = await api.post(`/api/v1/module`, {
      ...module,
    });

    return tempCreateCourseResponse.data.module;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const editModule = async (module: interfaces.IModule): Promise<interfaces.IModule> => {
  // Strip out any things we're not using in the backend
  const { lastUpdated, ...editedCourse } = module;
  try {
    const tempEditModuleResponse = await api.put(`/api/v1/module/${module.id}`, {
      ...editedCourse,
    });

    return tempEditModuleResponse.data;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

interface IModuleReorder {
  moduleId: interfaces.ID;
  courseId: interfaces.ID;
  order: number;
}

export const reorderModules = async (
  modulesToReorder: IModuleReorder[]
): Promise<interfaces.IModule[]> => {
  try {
    const tempReorderResponse = await api.put('/api/v1/modules/reorder', {
      modulesToReorder,
    });

    console.log({ tempReorderResponse });

    return tempReorderResponse.data?.modules;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const deleteModule = async (moduleId: interfaces.ID): Promise<any> => {
  try {
    const tempDeleteModuleResponse = await api.delete(`/api/v1/module/${moduleId}`);

    return tempDeleteModuleResponse.data.moduleId;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

import React from 'react';

import { AnnouncementListItem } from './AnnouncementListItem';
import { interfaces } from '../../common';

interface IProps {
  announcements: any;
}

export const AnnouncementList = ({ announcements }: IProps) => {
  return announcements && announcements.length > 0 ? (
    announcements.map((tempAnnouncement: interfaces.IAnnouncement) => (
      <AnnouncementListItem key={tempAnnouncement.id} announcement={tempAnnouncement} />
    ))
  ) : (
    <div>no results...</div>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, navigate } from '@reach/router';

import { MainRoutes, SubRoutes } from '../../constants';
import {
  interfaces,
  PageSection,
  PageHeader,
  Button,
  LinkButton,
  ErrorDisplay,
  openModal,
  Modal,
  ModalType,
} from '../../common';
import { IModuleStore } from '../reducers';
import { fetchSingleModule, editModule, deleteModule as deleteModuleAction } from '../actions';
import { ModuleForm } from '../components';
import { AudioAssetList } from '../../audioAssets';

interface IProps extends RouteComponentProps {
  moduleId?: interfaces.ID;
}

export const EditModule = ({ moduleId }: IProps) => {
  const { isLoading, error, modules } = useSelector(
    (state: { modules: IModuleStore }) => state.modules
  );
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentModule, setCurrentModule] = useState<interfaces.IModule | undefined>(undefined);
  const [currentEditableModule, setCurrentEditableModule] = useState<interfaces.IModule | null>(
    null
  );

  useEffect(() => {
    const module = modules.find((tempModule: interfaces.IModule) => tempModule.id === moduleId);

    if (moduleId) {
      dispatch(fetchSingleModule(moduleId));
    }

    if (module) {
      setCurrentModule(module);
      setCurrentEditableModule(module);
    }
  }, [moduleId, dispatch, modules]);

  const onUpdateModule = (updatedField: { [key: string]: any }) => {
    setCurrentEditableModule(
      currentEditableModule
        ? { ...currentEditableModule, ...updatedField }
        : { title: '', desc: null, level: 0, order: 0, isPublished: true, ...updatedField }
    );
  };

  const onSaveEdittedModule = async () => {
    if (!currentEditableModule) {
      return;
    }

    try {
      await dispatch(editModule(currentEditableModule));
      navigate(MainRoutes.Modules.path);
    } catch (error: any) {
      console.log('error — ', error);
    }
  };

  const onDeleteModule = () => {
    dispatch(
      openModal(
        <Modal
          title="Delete Playlist?"
          message="Are you sure? This will delete all associated audio assets!"
          onConfirm={deleteModule}
          type={ModalType.Danger}
        />
      )
    );
  };

  const deleteModule = async () => {
    navigate(MainRoutes.Modules.path);
    await dispatch(deleteModuleAction(currentModule && currentModule.id));
  };

  return (
    <PageSection>
      <PageHeader>
        <h1>{currentModule?.title}</h1>
        <Button onClick={() => setIsEditMode(!isEditMode)}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
      </PageHeader>
      <ErrorDisplay errorMessage={error} />
      <ModuleForm
        module={isEditMode ? currentEditableModule : currentModule || null}
        onUpdateForm={onUpdateModule}
        onSubmit={onSaveEdittedModule}
        isEditMode={isEditMode}
        onDelete={onDeleteModule}
      />
      <Button onClick={onSaveEdittedModule} isLoading={isLoading} disabled={!isEditMode}>
        Save
      </Button>
      <h2 style={{ margin: `24px 0px 6px 0px` }}>Audio Assets</h2>
      <AudioAssetList audioAssets={currentModule?.audioAssets} />
      <LinkButton
        style={{ marginTop: 12 }}
        to={`${MainRoutes.AudioAssets.path}${SubRoutes.Add}/${currentModule?.id}`}
      >
        Add
      </LinkButton>
    </PageSection>
  );
};

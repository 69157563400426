import React, { ChangeEvent, useRef } from 'react';

import {
  PageForm,
  Input,
  TextAreaInput,
  Row,
  InputGroup,
  InputLabel,
  interfaces,
  Checkbox,
  DangerButton,
} from '../../common';
import { ModuleSelect } from '../../modules';
import { formatFileName } from '../../utils';
import { AudioTypeSelect } from './AudioTypeSelect';

interface IProps {
  isEditMode: boolean;
  audioAsset: interfaces.IAudioAsset | null;
  onUpdateForm: (updatedField: { [key: string]: any }) => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const AudioAssetForm = ({
  isEditMode,
  audioAsset,
  onUpdateForm,
  onSubmit,
  onDelete,
}: IProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const onFileChange = () => {
    if (fileInput.current?.files) {
      onUpdateForm({ file: fileInput.current.files[0] });
    }
  };

  return (
    audioAsset && (
      <PageForm onSubmit={onSubmit}>
        <Row style={{ marginBottom: 6, height: 46 }}>
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onUpdateForm({ isPublished: !audioAsset.isPublished });
            }}
            label="Published"
            value={audioAsset.isPublished || false}
            disabled={!isEditMode}
          />
          {isEditMode && audioAsset.id && !audioAsset.isPublished && (
            <DangerButton
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                if (onDelete) {
                  onDelete();
                }
              }}
              style={{ marginLeft: 12 }}
            >
              Delete?
            </DangerButton>
          )}
        </Row>
        <Input
          placeholder="Audio Asset Title"
          value={audioAsset.title}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onUpdateForm({ title: event.target.value })
          }
          disabled={!isEditMode}
        />
        <TextAreaInput
          placeholder="Audio Asset Description"
          value={audioAsset.desc || ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onUpdateForm({ desc: event.target.value })
          }
          disabled={!isEditMode}
        />
        <InputGroup>
          <InputLabel>Module</InputLabel>
          <ModuleSelect
            value={audioAsset.moduleId || ''}
            disabled={!isEditMode}
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              onUpdateForm({ moduleId: event.target.value })
            }
          />
        </InputGroup>
        <Row>
          <InputGroup>
            <InputLabel>Level</InputLabel>
            <Input
              type="number"
              placeholder="Level"
              value={audioAsset.level}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ level: event.target.value })
              }
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Order in Module</InputLabel>
            <Input
              type="number"
              placeholder="Order in Module"
              value={audioAsset.order || 0}
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onUpdateForm({ order: event.target.value })
              }
            />
          </InputGroup>
          <InputGroup>
            <InputLabel>Type</InputLabel>
            <AudioTypeSelect
              disabled={!isEditMode}
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                onUpdateForm({ type: event.target.value })
              }
              value={audioAsset.type}
            ></AudioTypeSelect>
          </InputGroup>
        </Row>
        {isEditMode ? (
          <InputGroup>
            <InputLabel>Audio File</InputLabel>
            <Input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept="audio/mpeg,audio/mp3"
            />
          </InputGroup>
        ) : (
          <InputGroup>
            <InputLabel>Audio File</InputLabel>
            <a
              style={{ marginBottom: 12, marginTop: 2 }}
              href={audioAsset.url ? audioAsset.url : ''}
            >
              {formatFileName(audioAsset.filename)}
            </a>
          </InputGroup>
        )}
      </PageForm>
    )
  );
};

import { api } from './api';
import { interfaces } from '../common';

export const getAnnouncements = async (): Promise<interfaces.IAnnouncement[]> => {
  try {
    const tempAnnouncementResponse = await api.get(`/api/v1/announcements`);

    return tempAnnouncementResponse.data.announcements;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getAnnouncementById = async (id: interfaces.ID): Promise<interfaces.IAnnouncement> => {
  try {
    const tempAnnouncementResponse = await api.get(`/api/v1/announcements/${id}`);

    return tempAnnouncementResponse.data.announcement;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const createAnnouncement = async (
  announcement: interfaces.IAnnouncement
): Promise<interfaces.IAnnouncement> => {
  const tempFormData = generateFormDataFromAnnouncement(announcement);

  try {
    const createAnnouncementResponse = await api.post(`/api/v1/announcements`, tempFormData);
    return createAnnouncementResponse.data.announcement;
  } catch (error: any) {
    console.log('error caught — ');
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const editAnnouncement = async (
  announcement: interfaces.IAnnouncement
): Promise<interfaces.IAnnouncement> => {
  const tempFormData = generateFormDataFromAnnouncement(announcement);

  try {
    const tempEditAnnouncementResponse = await api.put(
      `/api/v1/announcements/${announcement.id}`,
      tempFormData
    );

    return tempEditAnnouncementResponse.data.announcement;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const deleteAnnouncement = async (id: interfaces.ID) => {
  try {
    const tempDeleteResponse = await api.delete(`/api/v1/announcements/${id}`);
    return tempDeleteResponse.data.announcementId;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

const generateFormDataFromAnnouncement = (announcement: interfaces.IAnnouncement): FormData => {
  const formData = new FormData();
  formData.append('title', announcement.title);
  formData.append('label', announcement.label);
  formData.append('desc', announcement.desc ? announcement.desc : '');
  formData.append('linkedContentItemId', announcement.linkedContentItemId as string);
  formData.append('isPublished', JSON.stringify(announcement.isPublished));

  if (announcement.contentDuration) {
    formData.append('contentDuration', announcement.contentDuration);
  }

  if (announcement.order) {
    formData.append('order', announcement.order.toString());
  }

  if (announcement.type) {
    formData.append('type', announcement.type);
  }

  if (announcement.imageUrl) {
    formData.append('imageUrl', announcement.imageUrl);
  }

  if (announcement.image) {
    formData.append('image', announcement.image);
  }

  return formData;
};

import { Dispatch } from 'redux';

import {
  getAnnouncements,
  createAnnouncement as createAnnouncementAPI,
  getAnnouncementById as getAnnouncementByIdAPI,
  editAnnouncement as editAnnouncementAPI,
  deleteAnnouncement as deleteAnnouncementAPI,
} from '../api';
import { interfaces } from '../common';
import { Time, isOlderThan } from '../utils';

export enum AnnouncementActionType {
  FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS',
  FETCH_ANNOUNCEMENTS_SUCCESS = 'FETCH_ANNOUNCEMENTS_SUCCESS',
  FETCH_SINGLE_ANNOUNCEMENT = 'FETCH_SINGLE_ANNOUNCEMENT',
  FETCH_SINGLE_ANNOUNCEMENT_SUCCESS = 'FETCH_SINGLE_ANNOUNCEMENT_SUCCESS',
  UPDATE_SINGLE_ANNOUNCEMENT = 'UPDATE_SINGLE_ANNOUNCEMENT',
  ADD_SINGLE_ANNOUNCEMENT = 'ADD_SINGLE_ANNOUNCEMENT',
  DELETE_SINGLE_ANNOUNCEMENT = 'DELETE_SINGLE_ANNOUNCEMENT',
  GENERIC_ANNOUNCEMENT_FAILURE = 'GENERIC_ANNOUNCEMENT_FAILURE',
}

export const fetchAnnouncements = () => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempLastUpdated = getState().announcements.lastUpdated;
    if (!isOlderThan(Time.OneMinute, tempLastUpdated)) {
      Promise.resolve();
      return;
    }

    dispatch(fetchAnnouncementsInit());
    try {
      const tempAnnouncements = await getAnnouncements();
      dispatch(fetchAnnouncementsSuccess(tempAnnouncements));
    } catch (error: any) {
      dispatch(genericAnnouncementFailure(error.message));
    }
  };
};

const fetchAnnouncementsInit = () => {
  return {
    type: AnnouncementActionType.FETCH_ANNOUNCEMENTS,
    payload: null,
  };
};

const fetchAnnouncementsSuccess = (announcements: interfaces.IAnnouncement[]) => {
  return {
    type: AnnouncementActionType.FETCH_ANNOUNCEMENTS_SUCCESS,
    payload: { announcements },
  };
};

export const fetchSingleAnnouncement = (id: interfaces.ID) => {
  return async (dispatch: Dispatch, getState: () => interfaces.IGlobalStoreState) => {
    const tempExistingAnnouncements = getState().announcements.announcements;
    const tempExistingAnnouncement = tempExistingAnnouncements.find(
      (tempAnnouncement: interfaces.IAnnouncement) => tempAnnouncement.id === id
    );

    try {
      if (tempExistingAnnouncement) {
        if (isOlderThan(Time.OneMinute, tempExistingAnnouncement.lastUpdated)) {
          // Refresh as it's out of date
          dispatch(fetchAnnouncementsInit());
          const tempAnnouncement = await getAnnouncementByIdAPI(id);
          dispatch(updateSingleAnnouncement(tempAnnouncement));
        }
      } else {
        // Doesnt exist at all, get a new version
        dispatch(fetchAnnouncementsInit());
        const tempAnnouncement = await getAnnouncementByIdAPI(id);
        dispatch(addSingleAnnouncement(tempAnnouncement));
      }
    } catch (error: any) {
      dispatch(genericAnnouncementFailure(error.message));
    }
  };
};

export const createAnnouncement = (announcement: interfaces.IAnnouncement) => {
  return async (dispatch: Dispatch): Promise<any> => {
    // using this mostly just so setting "isLoading" - consider making a generic for this
    dispatch(fetchAnnouncementsInit());
    try {
      const tempAnnouncement = await createAnnouncementAPI(announcement);
      dispatch(addSingleAnnouncement(tempAnnouncement));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAnnouncementFailure(error.message));
      return Promise.reject(error);
    }
  };
};

export const editAnnouncement = (announcement: interfaces.IAnnouncement) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(fetchAnnouncementsInit());
    try {
      const tempAnnouncement = await editAnnouncementAPI(announcement);
      dispatch(updateSingleAnnouncement(tempAnnouncement));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAnnouncementFailure(error.message));
      return Promise.reject(error);
    }
  };
};

export const deleteAnnouncement = (announcementId?: interfaces.ID) => {
  return async (dispatch: Dispatch): Promise<any> => {
    if (!announcementId) {
      const tempError = 'No ID passed for Announcement, could not delete';
      dispatch(genericAnnouncementFailure(tempError));
      return Promise.reject(tempError);
    }

    dispatch(fetchAnnouncementsInit());
    try {
      const deletedAnnouncementId = await deleteAnnouncementAPI(announcementId);
      dispatch(deleteSingleAnnouncement(deletedAnnouncementId));
      return Promise.resolve();
    } catch (error: any) {
      dispatch(genericAnnouncementFailure(error.message));
      return Promise.reject(error);
    }
  };
};

const deleteSingleAnnouncement = (announcementId: interfaces.ID) => {
  return {
    type: AnnouncementActionType.DELETE_SINGLE_ANNOUNCEMENT,
    payload: { id: announcementId },
  };
};

const addSingleAnnouncement = (announcement: interfaces.IAnnouncement) => {
  return {
    type: AnnouncementActionType.ADD_SINGLE_ANNOUNCEMENT,
    payload: { announcement },
  };
};

const updateSingleAnnouncement = (announcement: interfaces.IAnnouncement) => {
  return {
    type: AnnouncementActionType.UPDATE_SINGLE_ANNOUNCEMENT,
    payload: { announcement },
  };
};

const genericAnnouncementFailure = (errorMessage: string) => {
  return {
    type: AnnouncementActionType.GENERIC_ANNOUNCEMENT_FAILURE,
    payload: { errorMessage },
  };
};

import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { MainRoutes } from '../../constants';
import { Row, interfaces } from '../../common';

const AnnouncementContainer = styled(Link)`
  cursor: pointer;
  margin-bottom: 6px;
`;

const AnnouncementImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 6px;
  margin-right: 6px;
`;

interface IProps {
  announcement: interfaces.IAnnouncement;
}

export const AnnouncementListItem = ({ announcement }: IProps) => {
  return (
    <AnnouncementContainer to={`${MainRoutes.Announcements.path}/${announcement.id}`}>
      <Row>
        <AnnouncementImage src={announcement.imageUrl}></AnnouncementImage>
        <div>
          <div>{announcement.label}</div>
          <div>{announcement.title}</div>
        </div>
      </Row>
    </AnnouncementContainer>
  );
};

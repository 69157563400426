import { api } from './api';
import { interfaces } from '../common';

export const getDailyActiveUsers = async (): Promise<interfaces.IUser[]> => {
  try {
    const tempDailyUsersResponse = await api.get(`/api/v1/analytics/dau`);

    return tempDailyUsersResponse.data.users;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getAllUsers = async (): Promise<interfaces.IUser[]> => {
  try {
    const tempAllUsersResponse = await api.get(`/api/v1/users/all`);
    return tempAllUsersResponse.data.users;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getWeeklyUsers = async (): Promise<interfaces.IUser[]> => {
  try {
    const tempAllUsersResponse = await api.get(`/api/v1/analytics/weeklyUsers`);
    return tempAllUsersResponse.data.users;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getMonthlyUsers = async (): Promise<interfaces.IUser[]> => {
  try {
    const tempAllUsersResponse = await api.get(`/api/v1/analytics/monthlyUsers`);
    return tempAllUsersResponse.data.users;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getTrackData = async (): Promise<interfaces.ITrackData> => {
  try {
    const tempTrackDataResponse = await api.get(`/api/v1/analytics/trackData`);
    return tempTrackDataResponse.data.trackData;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getTrendingTracks = async (daysAgo?: number, tracksToReturn = 10): Promise<any> => {
  try {
    let daysAgoQuery = '';
    if (daysAgo) {
      daysAgoQuery = `&daysAgo=${daysAgo}`;
    }

    const tempTrackDataResponse = await api.get(
      `/api/v1/analytics/trending-tracks?limit=${tracksToReturn}${daysAgoQuery}`
    );

    return tempTrackDataResponse.data.trackData;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

import { interfaces } from '../common';
import { ModuleActionType } from './constants';

export interface IModuleStore {
  isLoading: boolean;
  modules: interfaces.IModule[];
  lastUpdated: number;
  error: string;
}

const initialModuleState: IModuleStore = {
  isLoading: false,
  modules: [],
  lastUpdated: 0,
  error: '',
};

export const moduleReducer = (
  state = initialModuleState,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ModuleActionType.FETCH_MODULES:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case ModuleActionType.FETCH_MODULES_BY_COURSE_ID:
      return {
        ...state,
        // ? Should we go more granular here and have a "isLoadingByCourseId"?
        isLoading: true,
        error: '',
      };
    case ModuleActionType.FETCH_MODULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        lastUpdated: Date.now(),
        modules: mergeModules([...state.modules, ...payload.modules]),
      };
    case ModuleActionType.FETCH_MODULES_BY_COURSE_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        modules: mergeModules([...state.modules, ...payload.modules]),
      };
    case ModuleActionType.ADD_SINGLE_MODULE:
      return {
        ...state,
        isLoading: false,
        error: '',
        modules: [...state.modules, { ...payload.module, lastUpdated: Date.now() }],
      };
    case ModuleActionType.UPDATE_SINGLE_MODULE:
      return {
        ...state,
        isLoading: false,
        error: '',
        modules: [
          ...state.modules.map((tempModule: interfaces.IModule) => {
            if (tempModule.id === payload.module.id) {
              return { ...payload.module, lastUpdated: Date.now() };
            }

            return tempModule;
          }),
        ],
      };
    case ModuleActionType.DELETE_SINGLE_MODULE:
      return {
        ...state,
        isLoading: false,
        error: '',
        modules: state.modules.filter(
          (tempModule: interfaces.IModule) => tempModule.id !== payload.id
        ),
      };
    case ModuleActionType.REORDER_MODULES_SUCCESS:
      const mergedModules = mergeModules([...state.modules, ...payload.modules]);
      return {
        ...state,
        modules: mergedModules,
      };
    case ModuleActionType.MODULES_GENERIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.errorMessage,
      };
    default:
      console.warn(`No reducer found for ${type}`);
      return { ...state };
  }
};

const mergeModules = (modules: interfaces.IModule[]) => {
  const tempMergedModules = modules.reduce(
    (accumulatedModules: interfaces.IModule[], currentModule: interfaces.IModule) => {
      let tempExistingModuleIndex = accumulatedModules.findIndex(
        (tempModule) => tempModule.id === currentModule.id
      );

      // We found a match, updating it!
      if (tempExistingModuleIndex !== -1) {
        accumulatedModules[tempExistingModuleIndex] = {
          ...accumulatedModules[tempExistingModuleIndex],
          ...currentModule,
        };
        return [...accumulatedModules];
      }

      return [...accumulatedModules, currentModule];
    },
    []
  );

  return tempMergedModules;
};

import React, { useEffect, useState, ReactNode } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';

import { IModuleStore, fetchCourseModuleById, ModuleListDnD } from '../../modules';
import { interfaces, ChevronButton } from '../../common';
import { SubRoutes } from '../../constants';

const CourseLink = styled(Link)``;

const CourseItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`;

const ModuleItemContainer = styled.div<{ isEmptyList: boolean; isDraggingInProgress?: boolean }>`
  padding-left: 24px;
  height: ${(props) => (props.isEmptyList ? '0px' : 'auto')};
  min-height: ${(props) => (props.isDraggingInProgress ? '32px' : '0')};
  margin-bottom: ${(props) => (props.isDraggingInProgress ? '6px' : '0px')};
`;

interface IProps {
  course: interfaces.ICourse;
  children?: ReactNode;
  index: number;
  provided?: DroppableProvided;
  isDragging?: boolean;
}

export const CourseItemWithModules = ({ course, index, provided, isDragging }: IProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const moduleState: IModuleStore = useSelector(
    (state: { modules: IModuleStore }) => state.modules
  );
  const { modules, isLoading } = moduleState;
  const courseModules = modules.filter((tempModule) => tempModule.courseId === course.id);
  const orderedCourseModules = courseModules.sort((a, b) => a.order - b.order);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourseModuleById(course.id));
  }, [dispatch, course.id]);

  const isEmptyModuleList = courseModules.length <= 0;
  const shouldShowDroppable = isExpanded || isEmptyModuleList;

  return (
    <div ref={provided?.innerRef}>
      <Draggable draggableId={course.id as string} index={index}>
        {(provided) => (
          <CourseItemContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {courseModules.length > 0 ? (
              <ChevronButton
                isLoading={isLoading}
                isExpanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            ) : (
              // So everything is aligned even when there isn't a button to display
              <div style={{ width: 22 }} />
            )}
            <CourseLink tabIndex={0} to={`${SubRoutes.Course}/${course.id}`}>
              {course.title}
            </CourseLink>
          </CourseItemContainer>
        )}
      </Draggable>
      {shouldShowDroppable && (
        <ModuleItemContainer isEmptyList={isEmptyModuleList} isDraggingInProgress={isDragging}>
          <ModuleListDnD
            modules={orderedCourseModules}
            courseId={course.id as string}
            isDraggingInProgress={isDragging}
          />
        </ModuleItemContainer>
      )}
      {provided?.placeholder}
    </div>
  );
};

import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';

import { SidebarLayout } from '../common';
import { SubRoutes } from '../constants';
import { AllCourses } from './containers/AllCourses';
import { AddCourse } from './containers/AddCourse';
import { EditCourse } from './containers/EditCourse';

export const Courses = (props: RouteComponentProps) => {
  return (
    <SidebarLayout>
      <Router>
        <AllCourses path="/" />
        <AddCourse path={`${SubRoutes.Add}`} />
        <EditCourse path={`/${SubRoutes.Course}/:courseId`} />
      </Router>
    </SidebarLayout>
  );
};

import { api } from './api';
import { interfaces } from '../common';

export const getCourses = async (): Promise<interfaces.ICourse[]> => {
  try {
    const tempCourseResponse = await api.get(`/api/v1/courses?includeUnpublished=true`);

    return tempCourseResponse.data.courses;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const getCourseById = async (id: interfaces.ID): Promise<interfaces.ICourse> => {
  try {
    const tempCourseResponse = await api.get(`/api/v1/course/${id}?includeUnpublished=true`);

    return tempCourseResponse.data;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const createCourse = async (course: interfaces.ICourse): Promise<interfaces.ICourse> => {
  try {
    const tempCourseFormData = generateFormDataFromCourse(course);
    const tempCreateCourseResponse = await api.post(`/api/v1/course`, tempCourseFormData);

    return tempCreateCourseResponse.data.course;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const editCourse = async (course: interfaces.ICourse): Promise<interfaces.ICourse> => {
  const tempCourseFormData = generateFormDataFromCourse(course);
  try {
    const tempEditCourseResponse = await api.put(`/api/v1/course/${course.id}`, tempCourseFormData);

    return tempEditCourseResponse.data.course;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

export const deleteCourse = async (courseId: interfaces.ID): Promise<interfaces.ID> => {
  try {
    const tempDeletedCourseResponse = await api.delete(`/api/v1/course/${courseId}`);
    return tempDeletedCourseResponse.data.courseId;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

const generateFormDataFromCourse = (course: interfaces.ICourse): FormData => {
  const formData = new FormData();
  formData.append('title', course.title);
  formData.append('isPublished', JSON.stringify(course.isPublished));

  if (course.desc) {
    formData.append('desc', course.desc);
  }

  if (course.averageTrackLength) {
    formData.append('averageTrackLength', course.averageTrackLength);
  }
  if (course.level) {
    formData.append('level', course.level.toString());
  }

  if (course.order) {
    formData.append('order', course.order.toString());
  }

  if (course.imageUrl) {
    formData.append('imageUrl', course.imageUrl);
  }

  if (course.image) {
    formData.append('image', course.image);
  }

  return formData;
};

interface ICourseReorder {
  id: interfaces.ID;
  order: number;
}

export const reorderCourses = async (
  coursesToReorder: ICourseReorder[]
): Promise<interfaces.IModule[]> => {
  try {
    const tempReorderResponse = await api.put('/api/v1/courses/reorder', {
      coursesToReorder,
    });

    console.log({ tempReorderResponse });

    return tempReorderResponse.data?.courses;
  } catch (error: any) {
    const tempErrorMessage = error.response?.data?.message
      ? error.response.data.message
      : error.message;
    throw new Error(tempErrorMessage);
  }
};

import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import { SidebarLayout } from '../common';
import { SubRoutes } from '../constants';
import { AddAnnouncement, AllAnnouncements, EditAnnouncement } from './containers';

export const Announcements = (props: RouteComponentProps) => {
  return (
    <SidebarLayout>
      <Router>
        <AllAnnouncements path="/" />
        <AddAnnouncement path={SubRoutes.RelativeAdd} />
        <EditAnnouncement path={`/:id`} />
      </Router>
    </SidebarLayout>
  );
};

import { palette } from './colorPalette';

export type ThemeType = {
  headerHeight: string;
  footerHeight: string;
  defaultFontSize: string;
  fontSizeH1: string;
  fontSizeH2: string;
  fontSizeH3: string;
  fontSizeH4: string;
  sidebarWidthLg: string;
  sidebarWidthMd: string;
  sidebarWidthSm: string;
  defaultBorderRadius: string;
  colors: {
    primary: string;
    primarySelected: string;
    primaryActivated: string;
    inputBorder: string;
    secondary: string;
    defaultFontColor: string;
    offWhite: string;
    disabled: string;
    disabledText: string;
    disabledBackground: string;
    white: string;
    black: string;
    placeholder: string;
    error: string;
    warning: string;
  };
};

export const theme: ThemeType = {
  headerHeight: '64px',
  footerHeight: '24px',
  defaultFontSize: '1rem',
  fontSizeH1: '4rem', // ~64px
  fontSizeH2: '1.75rem', // ~30px
  fontSizeH3: '1.5rem', // ~24px
  fontSizeH4: '1rem', // ~16px
  sidebarWidthLg: '284px',
  sidebarWidthMd: '164px',
  sidebarWidthSm: '64px',
  defaultBorderRadius: '6px',
  colors: {
    secondary: palette.teal600,
    primary: palette.primary,
    primarySelected: palette.purple500,
    primaryActivated: palette.purple400,
    // inputs
    inputBorder: palette.blueGrey400,
    offWhite: palette.blueGrey050,
    defaultFontColor: palette.primary,
    disabled: palette.blueGrey400,
    disabledText: palette.blueGrey500,
    disabledBackground: palette.blueGrey100,
    error: palette.redVivid500,
    warning: palette.yellowVivid600,
    white: '#FFF',
    black: palette.blueGrey500,
    placeholder: palette.blueGrey400,
  },
};

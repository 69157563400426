import React from 'react';
import styled from 'styled-components';

import { interfaces } from '../../common';

const AnnouncementPreviewContainer = styled.div`
  height: 250px;
  width: 250px;
  display: flex;
  color: white;
  border-radius: 16px;
  background-size: cover;
  box-shadow: 0px 4px 4px 1px #333333a6;
  margin-bottom: 6px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  background: linear-gradient(#33333300, #333333a6, #333333ff);
  border-radius: 16px;
`;

const Label = styled.div`
  margin-bottom: auto;
  margin-left: auto;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: bold;
  text-shadow: 0px 3px 6px #49a4dbc9;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const ContentDuration = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
`;

const Description = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 11px;
`;

interface IProps {
  announcement: interfaces.IAnnouncement;
  imageFile?: any | null;
}

export const AnnouncementPreview = ({ announcement, imageFile }: IProps) => {
  return (
    <AnnouncementPreviewContainer
      style={{
        backgroundImage: announcement.imageUrl
          ? `url(${announcement.imageUrl})`
          : imageFile
          ? `url(${URL.createObjectURL(imageFile)})`
          : `url('')`,
      }}
    >
      <ContentContainer>
        <Label>{announcement.label}</Label>
        <ContentDuration>{announcement.contentDuration}</ContentDuration>
        <Title>{announcement.title}</Title>
        <Description>{announcement.desc}</Description>
      </ContentContainer>
    </AnnouncementPreviewContainer>
  );
};

import { LocalStorageKey } from './localStorage';
import { MainRoutes, SubRoutes } from './routes';
import { Status } from './status';
import { MediaQuery } from './mediaQuery';

enum KeyPrefix {
  EmptyCourse = 'course-empty-',
}

export { LocalStorageKey, KeyPrefix, MainRoutes, SubRoutes, Status, MediaQuery };

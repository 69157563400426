import React, { useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { interfaces, Select, Option } from '../../common';
import { fetchModules } from '../actions';

interface IProps {
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

export const ModuleSelect = ({ value = '', onChange, disabled }: IProps) => {
  const modules = useSelector(({ modules }: interfaces.IGlobalStoreState) => modules.modules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModules());
  }, [dispatch]);

  return (
    modules && (
      <Select value={value} onChange={(event) => onChange(event)} disabled={disabled}>
        <Option key="none" value={''}>
          None
        </Option>
        {modules.map((tempModule: interfaces.IModule) => (
          <Option key={tempModule.id} value={tempModule.id}>
            {tempModule.title}
          </Option>
        ))}
      </Select>
    )
  );
};

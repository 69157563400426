import React, { useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { interfaces, Select, Option } from '../../common';
import { fetchAudioAssets } from '../actions';

interface IProps {
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

export const AudioAssetSelect = ({ value = '', onChange, disabled }: IProps) => {
  const audioAssets = useSelector(
    ({ audioAssets }: interfaces.IGlobalStoreState) => audioAssets.audioAssets
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAudioAssets());
  }, [dispatch]);

  return (
    audioAssets && (
      <Select value={value} onChange={(event) => onChange(event)} disabled={disabled}>
        <Option key="none" value={''}>
          None
        </Option>
        {audioAssets.map((tempAudioAsset: interfaces.IAudioAsset) => (
          <Option key={tempAudioAsset.id} value={tempAudioAsset.id}>
            {tempAudioAsset.title}
          </Option>
        ))}
      </Select>
    )
  );
};

import React from 'react';
import { RouteComponentProps } from '@reach/router';

import {
  AllUsers,
  WeeklyUsers,
  MonthlyUsers,
  DailyActiveUsers,
  CourseContent,
  ModuleContent,
  AnnouncementContent,
  AudioContent,
  TrackPlayContent,
  TrendingWeeklyTracks,
  TrendingMonthlyTracks,
  TrendingAllTimeTracks,
} from '../components';
import { MainRoutes } from '../../constants';
import { PageSection, PageHeader, MetricsGrid, ListGrid } from '../../common';

export const DashboardMetrics = (props: RouteComponentProps) => {
  return (
    <PageSection>
      <PageHeader>
        <h1>{MainRoutes.Dashboard.name}</h1>
      </PageHeader>
      <h2>Content Overview</h2>
      <MetricsGrid>
        <CourseContent />
        <ModuleContent label="Modules" isLibraryContentOnly={false} />
        <ModuleContent label="Collections" isLibraryContentOnly />
        <AudioContent />
        <AnnouncementContent />
      </MetricsGrid>
      <h2>User Activity</h2>
      <MetricsGrid>
        <AllUsers />
        <DailyActiveUsers />
        <WeeklyUsers />
        <MonthlyUsers />
        <TrackPlayContent label="Total Plays" dataKey="allTimePlays" />
        <TrackPlayContent label="Daily Plays" dataKey="dailyPlays" />
        <TrackPlayContent label="Weekly Plays" dataKey="weeklyPlays" />
        <TrackPlayContent label="Monthly Plays" dataKey="monthlyPlays" />
      </MetricsGrid>
      <h2>Track Activity</h2>
      <ListGrid>
        <TrendingWeeklyTracks />
        <TrendingMonthlyTracks />
        <TrendingAllTimeTracks />
      </ListGrid>
    </PageSection>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { SubRoutes, MainRoutes } from '../../constants';
import { interfaces } from '../../common';

const ModuleLink = styled(Link)``;

interface IProps {
  module: interfaces.IModule;
}

export const ModuleItem = ({ module }: IProps) => {
  return (
    <ModuleLink to={`${MainRoutes.Modules.path}/${SubRoutes.Module}/${module.id}`}>
      {module.title}
    </ModuleLink>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorDisplay, DataField, MetricsGrid, interfaces } from '../../common';
import { fetchAudioAssetAnalyticsById } from '../../audioAssets/actions';

interface IProps {
  id: interfaces.ID;
}

export const AudioAssetAnalytics = ({ id }: IProps) => {
  const { audioAssets }: interfaces.IGlobalStoreState = useSelector(
    (state: interfaces.IGlobalStoreState) => state
  );
  const { audioAssetAnalytics: analytics, error } = audioAssets;
  const trackAnalytics = analytics[id];
  const completionRate =
    (trackAnalytics &&
      trackAnalytics?.completionRatePercentage &&
      `${trackAnalytics?.completionRatePercentage}%`) ||
    '—';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAudioAssetAnalyticsById(id));
  }, [dispatch, id]);

  return (
    <MetricsGrid>
      <DataField>
        <h4>Times Completed</h4>
        <h3>{trackAnalytics ? trackAnalytics.timesCompleted : '—'}</h3>
      </DataField>
      <DataField>
        <h4>Time Abandoned</h4>
        <h3>{trackAnalytics ? trackAnalytics.timesAbandoned : '—'}</h3>
      </DataField>
      <DataField>
        <h4>Completion Rate</h4>
        <h3>{trackAnalytics ? completionRate : '—'}</h3>
      </DataField>
      <div />
      <DataField>
        <h4>Plays Today</h4>
        <h3>{trackAnalytics ? trackAnalytics.dailyPlays : '—'}</h3>
      </DataField>
      <DataField>
        <h4>Plays This Week</h4>
        <h3>{trackAnalytics ? trackAnalytics.weeklyPlays : '—'}</h3>
      </DataField>
      <DataField>
        <h4>Plays This Month</h4>
        <h3>{trackAnalytics ? trackAnalytics.monthlyPlays : '—'}</h3>
      </DataField>
      <ErrorDisplay errorMessage={error} />
    </MetricsGrid>
  );
};
